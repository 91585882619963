.container-simple{
  padding: 10px;
}

.container-form {
  max-width: 700px;
  margin: 0 auto;
}

.form-register {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}

.content-button {
  grid-column: 1/-1;
  justify-self: center;
  min-width: 320px;
}

.error-message {
  color: red;
}

@media (min-width: 768px) {
  .form-register {
    grid-template-columns: repeat(2, 1fr);
  }
  
}
