.btnRound {
  background-color: #304aeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 10px 1.5rem;
  border: none;
  cursor: pointer;
}

.btnIcon {
  margin-right: 10px;
}
