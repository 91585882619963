.formFields {
    max-width: 100%;
    margin: 0 auto;
    justify-content: space-around;
    grid-column-gap: 20px;
}

.btnForm {
    width: 300px;
    margin: 0 auto;
  }

@media (min-width: 768px) {
    .formFields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  