.fullModal {
  position: fixed;
  z-index: 200;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  top: 15%;
  left: 3%;
  width: 95%;
  transition: all 0.3s ease-out;
}

.modalOpen {
  animation: openModal 0.4s ease-out forwards;
}

.modalClosed {
  animation: closeModal 0.4s ease-out forwards;
}

.modalHeader {
  width: 100%;
  text-align: right;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  margin-top: -42px;
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(60%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.closeIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

@media (min-width: 375px) {
}
@media (min-width: 768px) {
  .fullModal {
    top: 15%;
    left: 25%;
    width: 50%;
  }
}
