.descriptionSub {
  padding: 0 1rem 0.5rem 2rem;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
}

.radioOptionBrandRegister {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #f1f1f1;
  width: 310px;
  margin: 10px auto;
  text-align: left;
  padding: 1rem;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
}

.contentSelect {
  max-width: 310px;
  margin: 0 auto;
}

.contentForm {
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.contentButton {
  width: 300px;
  margin: 0 auto;
  padding: 2rem 0 3rem 0;
  text-align: center;
}
.contentRadioSelect {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .contentRadioSelect{
    width: 440px;
  }
}
