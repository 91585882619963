.link-back {
  color: #0f0f0f;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  box-sizing: border-box;
  cursor: pointer;
}

.section-title {
  text-align: center;
}

.item-marcas {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 1rem;
}

.item-marcas > img {
  vertical-align: middle;
}

.text-description {
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 2rem;
}

.subtitle-modle {
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
}

.price {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  margin-top: 0;
}

.currency {
  color: #244af5;
  font-size: 15px;
}

.lablel-price {
  margin: 0;
}

.content-btn {
  text-align: center;
  width: 300px;
  margin: 0 auto;
}

.content-btn-back {
  margin-top: 4rem;
}
