.inputForm {
  font-size: 16px;
  outline: 0;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 16px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0;
  color: #757575;
}

.inputSelect {
  -webkit-appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS43Nzk4NSAwLjIxOTk1QzkuOTIwMyAwLjM2MDU3NiA5Ljk5OTE5IDAuNTUxMiA5Ljk5OTE5IDAuNzQ5OTVDOS45OTkxOSAwLjk0ODcwMSA5LjkyMDMgMS4xMzkzMyA5Ljc3OTg1IDEuMjc5OTVMNS41Mjk4NSA1LjUyOTk1QzUuMzg5MjIgNS42NzA0IDUuMTk4NiA1Ljc0OTI5IDQuOTk5ODUgNS43NDkyOUM0LjgwMTEgNS43NDkyOSA0LjYxMDQ3IDUuNjcwNCA0LjQ2OTg1IDUuNTI5OTVMMC4yMTk4NDcgMS4yNzk5NUMwLjA4NzM2NjUgMS4xMzc3OCAwLjAxNTI0MzMgMC45NDk3MjkgMC4wMTg2NzE1IDAuNzU1NDI4QzAuMDIyMDk5OCAwLjU2MTEyNyAwLjEwMDgxMiAwLjM3NTc0MSAwLjIzODIyNSAwLjIzODMyOEMwLjM3NTYzOCAwLjEwMDkxNSAwLjU2MTAyMyAwLjAyMjIwMzcgMC43NTUzMjQgMC4wMTg3NzU1QzAuOTQ5NjI1IDAuMDE1MzQ3MiAxLjEzNzY3IDAuMDg3NDcwMiAxLjI3OTg1IDAuMjE5OTVMNC45OTk4NSAzLjkzOTk1TDguNzE5ODUgMC4yMTk5NUM4Ljg2MDQ3IDAuMDc5NDk5NyA5LjA1MTEgMC4wMDA2MTAzNTIgOS4yNDk4NSAwLjAwMDYxMDM1MkM5LjQ0ODYgMC4wMDA2MTAzNTIgOS42MzkyMiAwLjA3OTQ5OTcgOS43Nzk4NSAwLjIxOTk1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==),
    linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
