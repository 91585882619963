.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: #6a708e;
  border-style: dashed;
  background-color: #fff;
  color: #000;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzoneTitle {
  font-weight: 800;
}
.warning {
  position: absolute;
  bottom: 20px;
  padding: 1rem;
  background-color: #fbefef;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #c02b2b;
  width: 90%;
  text-align: left;
}

.loadingContainer {
  padding: 2rem;
}
