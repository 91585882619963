@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@font-face {
  font-family: 'Wingdings 3';
  font-style: normal;
  font-weight: normal;
  src: local('Protestant'), url(/static/media/Wingdings3.78d41af1.ttf) format('ttf');
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
}

.LoginForm_mainLogo__1wb_- {
  width: 175px;
  height: auto;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.LoginForm_containerForm__3yiy9 {
  text-align: center;
  padding: 1rem;
}

.LoginForm_contentErrors__2Im-R {
  width: 325px;
  margin: 1rem auto;
}

.LoginForm_icon__2B36w {
  padding: 1rem 1rem;
  max-width: 3rem;
  position: absolute;
  width: 50px;
  height: 50px;
}

.LoginForm_btnLogin__wT-Hj {
  width: 300px;
  margin: 0 auto;
}
.LoginForm_inputDanger__3sFDt {
  border: 1px solid #ffdddc !important;
  color: #cd1514;
}

.LoginForm_inputField__26Iz7 {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 15rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 3rem;
  text-align: left;
  box-sizing: border-box;
}

.LoginForm_inputField__26Iz7:focus + .LoginForm_label-helper__1ete6 {
  visibility: visible;
}

.LoginForm_inputField__26Iz7:focus {
  border: 1px solid #99a0c1;
  border-radius: 27.5px;
  outline: none;
}

.LoginForm_contentInput__3mDLF {
  padding: 0.3rem;
}

@media (min-width: 768px) {
  .LoginForm_inputField__26Iz7 {
    width: 20rem;
  }

  .LoginForm_mainLogo__1wb_- {
    width: 265px;
    height: 139px;
  }
}

@charset "UTF-8";
@font-face {
  font-family: 'Notification';
  src: url(/static/media/notification.3657084d.eot);
  src: url(/static/media/notification.3657084d.eot?#iefixs3g3t9) format('embedded-opentype'),
    url(/static/media/notification.c392cd33.woff) format('woff'),
    url(/static/media/notification.c5d9251e.ttf) format('truetype'),
    url(/static/media/notification.5d015867.svg#notification) format('svg');
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 40%;
  left: calc(10% - 40px);
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 40px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4 !important;
}

.notification-info:before {
  content: '';
}

.notification-success {
  background-color: #51a351 !important;
}

.notification-success:before {
  content: '';
}

.notification-warning {
  background-color: #f89406 !important;
}

.notification-warning:before {
  content: '';
}

.notification-error {
  background-color: #bd362f !important;
}

.notification-error:before {
  content: '';
}

@media (min-width: 375px) {
  .notification-container {
    top: 35%;
    left: calc(20% - 40px);
  }
}
@media (min-width: 768px) {
  .notification-container {
    top: 35%;
    left: calc(35% - 40px);
  }
}

@media (min-width: 992px) {
  .notification-container {
    top: 35%;
    left: calc(38% - 40px);
  }
}

@media (min-width: 1200px) {
  .notification-container {
    top: 35%;
    left: calc(43% - 40px);
  }
}

.resetPassword_containerError__2qf6B {
  max-width: 320px;
  margin: 0 auto;
}

.resetPassword_containerForm__1URfT {
  text-align: center;
  padding: 1rem;
  max-width: 320px;
  margin: 0 auto;
}

.resetPassword_description__3Qy6F {
  font-size: 15px;
  padding: 0 2rem 2rem 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.resetPassword_containerForm__1URfT > form > div{
  padding-bottom: 10px;
}

.InputFloatLabel_groupForm__2IFdi {
  position: relative;
}

.InputFloatLabel_floatLabel__2E9Yb {
  font-size: 13px;
  position: absolute;
  top: 8px;
  left: 10px;
  color: #757575;
  padding-left: 1rem;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: translate(0, 16px) scale(1);
          transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;
}

.InputFloatLabel_inputActive__2qZfT {
  color: #304aeb;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  -webkit-transform: translate(0, 10px) scale(0.75);
          transform: translate(0, 10px) scale(0.75);
}

.InputFloatLabel_inputForm__bdl8k {
  font-size: 13px;
  outline: 0;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 23px 22px 10px 22px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0;
}

.InputFloatLabel_invalid__7Tv_y {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #000;
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
          animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  100%,
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  100%,
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Button_button__2lgkF {
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  font-weight: 700;
  font-size: 14px;

  box-sizing: border-box;
}

.Button_btnConfirm__Jp7Ru {
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(
    93.52deg,
    #304aeb 12.6%,
    #00b9c5 65.73%,
    #73edb0 111.65%
  );
}

.Button_btnCancel__2FPfv {
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(92.11deg, #eb6767 1.89%, #6815d2 100%);
}

.Button_disabled__2F8yf {
  background: #e4e1e1;
  font-weight: bold;
  color: #8f8f8f;
  font-size: 14px;
}

/* @media (min-width: 375px) {
  .button {
    width: 15rem;
  }
}
@media (min-width: 768px) {
  .button {
    width: 15rem;
  }
} */

.container-simple{
  padding: 10px;
}

.container-form {
  max-width: 700px;
  margin: 0 auto;
}

.form-register {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}

.content-button {
  grid-column: 1/-1;
  justify-self: center;
  min-width: 320px;
}

.error-message {
  color: red;
}

@media (min-width: 768px) {
  .form-register {
    grid-template-columns: repeat(2, 1fr);
  }
  
}

.VerificationCode_containerModule__xH1LY {
  max-width: 700px;
  margin: 0 auto;
}

.VerificationCode_containerForm__3Fq_i{
  max-width: 320px;
  margin: 0 auto;
}
.ButtonHelp_btnHelp__118BC {
  position: fixed;
  z-index: 10;
  right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: none;
  background: #44d8b9;
  padding: 10px 10px 0 11px;
  top: 30%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}

.ButtonHelp_imgBtnHelp__2fVxl {
  padding-top: 0.3rem;
  width: 33px;
}

.ButtonHelp_btnContent__3lKVf {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none;
}

.ButtonHelp_labelIconHelp__2ypig {
  color: #166454;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  padding: 3px 0 9px;
}

.header-component {
  height: 3.7rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  z-index: 90;
}

.logo {
  height: 100%;
}

.img-log {
  width: 76px;
  height: 62px;
}

.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
}

.DrawerToggle {
  width: 35px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle div {
  width: 90%;
  height: 3px;
  margin: 4px 0;
  background-color: #000;
}

.side-bar {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 3.7rem;
  z-index: 200;
  background-color: white;
  box-shadow: 0px 34px 34px rgba(0, 0, 0, 0.05);
  padding: 1rem 1rem;
  box-sizing: border-box;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.close {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.container-menu {
  display: grid;
  grid-auto-rows: 50px;
  grid-gap: 5px;
  -webkit-align-items: center;
          align-items: center;
}

.menu-item {
  display: grid;
  grid-template-columns: 1fr 30px;

  -webkit-align-items: center;

          align-items: center;
  align-self: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #262626;
  border-bottom: 1px solid #f5f5f5;
}

.menu-item > a {
  text-decoration: none;
  padding-bottom: 10px;
}

.menu-item > a:visited {
  color: #262626;
}
.menu-item > a:hover {
  color: #304aeb;
}

.menu-item > img {
  justify-self: end;
}

.notification {
  background-color: #44d8b9;
  padding: 1px 10px;
  /* font-size: 12px; */
  border-radius: 11.5px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.03em;
}

.btn-round-close {
  background-color: #304aeb;
  padding: 3px;
  margin: 0 auto;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.iconx {
  padding-top: 17px;
  width: 12px;
}

.footer-menu-side {
  padding: 1rem 0;
}

.icons-footer-side {
  padding: 0;
}
.icons-footer-side > a {
  padding-right: 2rem;
}

.text-footer-side {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #353535;
  padding-bottom: 0;
}

.text-footer-side > a {
  text-decoration: none;
  color: #353535;
}

.text-footer-side > a > p {
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .side-bar {
    position: fixed;
    width: 300px;
    max-width: 70%;
    height: 100%;
    right: 0;
    top: 3.7rem;
    z-index: 200;
    background-color: white;
    box-shadow: 0px 34px 34px rgba(0, 0, 0, 0.05);
    padding: 1rem 1rem;
    box-sizing: border-box;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  }

  .menu-item {
    font-size: 18px;
  }

  .notification {
    padding: 3px 15px;
  }

  .icons-footer-side {
    padding: 1rem 0;
  }
  .text-footer-side {
    padding-bottom: 2;
  }
}

.profile-menu {
  position: fixed;
  width: 200px;
  height: 210px;
  right: 1rem;
  top: 3.7rem;
  z-index: 50;
  background-color: white;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 10px 10px;
  padding: 0rem 1rem;
  box-sizing: border-box;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.close-profile {
  display: none;
}

.open-profile {
  display: block;
}

.container-menu-profile {
  display: grid;
  grid-auto-rows: 50px;
  grid-gap: 5px;
  -webkit-align-items: center;
          align-items: center;
}

.menu-item-profile {
  display: grid;
  grid-template-columns: 1fr 30px;
  -webkit-align-items: center;
          align-items: center;
  align-self: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  border-bottom: 1px solid #f5f5f5;
}

.menu-item-profile > a {
  text-decoration: none;
  color: #000000;
  padding-bottom: 10px;
}

.menu-item-profile:last-child {
  border-bottom: none;
}

.menu-item-profile > img {
  justify-self: end;
}

.user-name {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #171717;
}

.content-buttons-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.content-buttons-modal > div {
  min-width: 150px;
  margin: 6px;
}

.Footer_footer__3_QJ9 {
  position: relative;
  margin-top: -50px; /* negative value of footer height */
  clear: both;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  letter-spacing: 0.1em;
  color: #2d2d2d;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .Footer_footer__3_QJ9 {
    padding: none;
  }
}

.WideFooter_footerWide__2xTCM {
  position: relative;
  padding: 1rem 3rem;
  margin-top: -130px;
  clear: both;
  text-align: left;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.1em;
  background-color: #304aeb;
  color: #ffffff;
  box-sizing: border-box;
}

.WideFooter_mainContentFooter__1vCQt {
  padding: 0.58rem 0;
}

.WideFooter_logoFooter___q_ew {
  padding-bottom: 0.6rem;
}

@media (min-width: 768px) {
  .WideFooter_footerWide__2xTCM {
    padding: 1rem 10rem;
  }
}

.SelectedClasses_module__12j3v {
  padding: 1rem 0;
  /* width: 100%; */
  text-align: center;
}

.SelectedClasses_title__3CRrL {
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
}

.SelectedClasses_item__1f9K7 {
  background-color: #f2f2f2;
  padding: 1rem;
  margin: 10px;
  box-sizing: border-box;
  text-align: left;
}

.SelectedClasses_nameItem__3Xn0N {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
}
.SelectedClasses_descItem__2f5Hj {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}

.SelectedClasses_closeIcon__4qKHl {
  color: #a4a4a4;
  font-size: 20px;
  float: right;
  margin: -10px 0;
  cursor: pointer;
}

.SelectedClasses_estimacion__2FPAk{
  color:blue;
  padding-left: 0;
}

@media (min-width: 375px) {
  .SelectedClasses_title__3CRrL {
    font-size: 18px;
  }
  .SelectedClasses_module__12j3v {
    padding: 0 1rem;
  }
 
}

@media (min-width: 768px) {
  .SelectedClasses_estimacion__2FPAk{
    padding-left: 1rem;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 3.7rem;
  background: rgba(252, 252, 252, 0.9);
}

.backdropFull {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: rgba(252, 252, 252, 0.9);
}

.custom-modal {
  position: fixed;
  z-index: 200;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  top: 15%;
  left: 3%;
  width: 95%;
  transition: all 0.3s ease-out;
}
.ct-modal-header {
  width: 100%;
  text-align: right;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.modal-close-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.input-modal-field {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 14rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

/*** Animations **/

.modal-open {
  -webkit-animation: openModal 0.4s ease-out forwards;
          animation: openModal 0.4s ease-out forwards;
}

.modal-closed {
  -webkit-animation: closeModal 0.4s ease-out forwards;
          animation: closeModal 0.4s ease-out forwards;
}

@-webkit-keyframes closeModal {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@-webkit-keyframes openModal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@media (min-width: 375px) {
  .input-modal-field {
    width: 16rem;
  }
  .modal-header {
    padding: 0.5rem 0.5rem;
  }
  .ct-modal-content {
    padding: 0 0.5rem;
  }
}
@media (min-width: 768px) {
  .input-modal-field {
    width: 20rem;
  }
  .custom-modal {
    top: 15%;
    left: 25%;
    width: 50%;
  }
}

.FullModal_fullModal__mDsTd {
  position: fixed;
  z-index: 200;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  top: 15%;
  left: 3%;
  width: 95%;
  transition: all 0.3s ease-out;
}

.FullModal_modalOpen__3nail {
  -webkit-animation: FullModal_openModal__2Ue_S 0.4s ease-out forwards;
          animation: FullModal_openModal__2Ue_S 0.4s ease-out forwards;
}

.FullModal_modalClosed__34M7M {
  -webkit-animation: FullModal_closeModal__3nNFv 0.4s ease-out forwards;
          animation: FullModal_closeModal__3nNFv 0.4s ease-out forwards;
}

.FullModal_modalHeader__3q8Oq {
  width: 100%;
  text-align: right;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  margin-top: -42px;
}

@-webkit-keyframes FullModal_closeModal__3nNFv {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes FullModal_closeModal__3nNFv {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@-webkit-keyframes FullModal_openModal__2Ue_S {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes FullModal_openModal__2Ue_S {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.FullModal_closeIcon__31fUV {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

@media (min-width: 375px) {
}
@media (min-width: 768px) {
  .FullModal_fullModal__mDsTd {
    top: 15%;
    left: 25%;
    width: 50%;
  }
}

.ClassesList_classesList__2ozLH {
  text-align: left;
  padding-top: 1rem;
  padding-left: 2rem;
  max-height: 178px;
  overflow-y: auto;
}

/* Customize the label (the container) */
.ClassesList_checkitem__1gmVO {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 220%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ClassesList_checkitem__1gmVO input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ClassesList_checkmark__Wn5jZ {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.ClassesList_checkitem__1gmVO:hover input ~ .ClassesList_checkmark__Wn5jZ {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
.ClassesList_checkitem__1gmVO input:checked ~ .ClassesList_checkmark__Wn5jZ {
  background-color: #244af5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ClassesList_checkmark__Wn5jZ:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ClassesList_checkitem__1gmVO input:checked ~ .ClassesList_checkmark__Wn5jZ:after {
  display: block;
}

/* Style the checkmark/indicator */
.ClassesList_checkitem__1gmVO .ClassesList_checkmark__Wn5jZ:after {
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  border: solid white;
  border-width: 2px;
}

@media (min-width: 375px) {
  .ClassesList_classesList__2ozLH {
    padding-left: 4rem;
  }
}

.Search_search__25p_t {
  width: 30rem;
  margin: 0;
  max-width: 80%;
  box-sizing: border-box;
}

.Search_inputModalSearch__1eGSH {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 14rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

input:focus,
input,
.Search_inputModalSearch__1eGSH :focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

@media (min-width: 375px) {
  .Search_inputModalSearch__1eGSH {
    width: 16rem;
  }

  .Search_search__25p_t {
    margin: 1rem auto;
  }
}

@media (min-width: 768px) {
  .Search_inputModalSearch__1eGSH {
    width: 20rem;
  }
}

.modal-title {
  margin-top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.modal-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}

.modal-head {
  text-align: left;
}

.modal-main-content {
  text-align: center;
}

.ct-modal-content {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
}

.modal-form {
  padding-bottom: 1.5rem;
}

.search-content {
  max-width: 400px;
  margin: 0 auto;
}

.button-modal {
  margin: 0.5rem auto;
  width: 60%;
}

@media (min-width: 375px) {
  .modal-title {
    margin-top: -20px;
  }
  .ct-modal-content {
    font-family: 'Montserrat', sans-serif;
    padding: 0 1rem;
  }
}

.dragModal_dropzone__2tfnC {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 90px 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: #6a708e;
  border-style: dashed;
  background-color: #fff;
  color: #000;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dragModal_dropzoneTitle__3zYDC {
  font-weight: 800;
}
.dragModal_warning__Zq_Fv {
  position: absolute;
  bottom: 20px;
  padding: 1rem;
  background-color: #fbefef;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #c02b2b;
  width: 90%;
  text-align: left;
}

.dragModal_loadingContainer__1iqdj {
  padding: 2rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 6px;
  border: 6px solid #ff2058;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff2058 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ButtonRound_btnRound__2vZi1 {
  background-color: #304aeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 10px 1.5rem;
  border: none;
  cursor: pointer;
}

.ButtonRound_btnIcon__3Z6B7 {
  margin-right: 10px;
}

.ButtonEdit_btnEdit__1zFk2 {
  background-color: #304aeb;
  color: #ffffff;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 8px 18px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.ButtonEdit_btnEdit__1zFk2:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
}

.ButtonEdit_btnLarge__1fBPy {
  width: 133px;
  text-align: left;
  height: 35px;
}

.ButtonEdit_btnIcon__2rFag {
  margin-right: 10px;
  height: 15px;
  width: 15px !important;
}

.ButtonBack_linkBack__p7lWn {
  color: #0f0f0f;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  box-sizing: border-box;
  cursor: pointer;
}

.ButtonBack_iconBack__WWdLm {
  padding-right: 15px;
}

.BtnCallToAction_btnIconRoundBlue__2APvK {
  cursor: pointer;
  background-color: #304aeb;
  margin: 10px auto;
  color: #ffffff;
  font-weight: bold;
  border-radius: 30px;
  max-width: 440px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 150%;
  text-align: left;
  padding: 20px 16px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 185px 2fr;
}

.BtnCallToAction_styleIcon__3ZZ0S {
  font-size: 19px;
  -webkit-align-self: center;
          align-self: center;
  justify-self: end;
  margin-left: 10px;
}

.BtnCallToAction_fileName__2ZOzP {
  font-weight: bold;
  max-width: 324px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.BtnCallToAction_btnGreen__1Fcnh {
  background-color: #44d8b9;
  padding: 10px 19px;
  font-size: 10px;
  font-weight: normal;
  color: #121f61;
}

.BtnCallToAction_btnDownload__1VujP {
  background-color: #44d8b9;
  color: #121f61;
  grid-template-columns: 250px 2fr !important;
}

@media (min-width: 375px) {
  .BtnCallToAction_btnIconRoundBlue__2APvK {
    font-size: 13px;
    grid-template-columns: 254px 2fr;
  }
  .BtnCallToAction_btnGreen__1Fcnh {
    padding: 10px 36px !important;
  }
}

@media (min-width: 768px) {
  .BtnCallToAction_btnIconRoundBlue__2APvK {
    padding: 20px 40px;
    grid-template-columns: 340px 2fr;
  }
}

.ErrorMessage_msgError__1jK0y {
  font-weight: 500;
  font-size: 14px;
  color: #cd1414;
  border: solid 1px #cd1414;
  width: 100%;
  margin: 1rem auto;
  border-radius: 19px;
  padding: 0.4rem;
  background-color: #fff9f9;
  box-sizing: border-box;
}

.TextArea_textArea__1AINi {
  font-family: Montserrat;
  font-size: 13px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 16px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
  margin: 0.5rem 0;
  
}


.TextArea_contentTextArea__1jwgR{
  margin: 0 auto;
  width: 98%;
}
.TextArea_textArea__1AINi:focus {
  outline: none;
}

.TextArea_invalid__2EffR {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}

.ItemMenu_serviciosButton__2oc8T {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  height: 6.25rem;
  margin: 0 1% 1rem 1%;
  padding: 1rem 3rem 1rem 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1rem;
  text-align: left;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: bold;
  background-color: white;
  color: black;
  background-image: url(/static/media/row-right.09449107.svg);
  background-position: right 1rem center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0);
  transition: box-shadow 0.4s;
  outline: none;
  border: none;
}

.ItemMenu_serviciosButton__2oc8T:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  color: #304aeb !important;
}

.ItemMenu_serviciosButton__2oc8T img {
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .ItemMenu_serviciosButton__2oc8T {
    -webkit-flex-basis: 48%;
            flex-basis: 48%;
    max-width: 25rem;
  }
}

.ItemMenuLight_serviciosButton__2KH9a {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    height: 6.25rem;
    margin: 0 1% 1rem 1%;
    padding: 1rem 3rem 1rem 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1rem;
    text-align: left;
    line-height: 130%;
    background-color: white;
    color: black;
    background-image: url(/static/media/row-right.09449107.svg);
    background-position: right 1rem center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0);
    transition: box-shadow 0.4s;
    outline: none;
    border: none;
  }
  
  .ItemMenuLight_serviciosButton__2KH9a:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
    color: #304aeb !important;
  }
  
  .ItemMenuLight_serviciosButton__2KH9a img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
  
  @media (min-width: 768px) {
    .ItemMenuLight_serviciosButton__2KH9a {
      -webkit-flex-basis: 48%;
              flex-basis: 48%;
      max-width: 25rem;
    }
  }
  
.ItemList_mainContent__2Q3zk {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #f2f2f2;
  padding: 1rem;
  margin: 10px;
  box-sizing: border-box;
  text-align: left;
}

.InputSimpleFloatLabel_groupForm__2Ieog {
  position: relative;
}

.InputSimpleFloatLabel_floatLabel__1zA2r {
  font-size: 13px;
  position: absolute;
  top: 8px;
  left: 10px;
  color: #757575;
  padding-left: 1rem;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: translate(0, 16px) scale(1);
          transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;
}

.InputSimpleFloatLabel_inputActive__33Vmn {
  color: #304aeb;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  -webkit-transform: translate(0, 10px) scale(0.75);
          transform: translate(0, 10px) scale(0.75);
}

.InputSimpleFloatLabel_inputForm___lcj- {
  font-size: 13px;
  outline: 0;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 23px 22px 10px 22px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0;
}

.InputSimpleFloatLabel_invalid__18q4A {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}

.InputDropDown_inputForm__2Ki28 {
  font-size: 16px;
  outline: 0;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 16px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0;
  color: #757575;
}

.InputDropDown_inputSelect__294Ey {
  -webkit-appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS43Nzk4NSAwLjIxOTk1QzkuOTIwMyAwLjM2MDU3NiA5Ljk5OTE5IDAuNTUxMiA5Ljk5OTE5IDAuNzQ5OTVDOS45OTkxOSAwLjk0ODcwMSA5LjkyMDMgMS4xMzkzMyA5Ljc3OTg1IDEuMjc5OTVMNS41Mjk4NSA1LjUyOTk1QzUuMzg5MjIgNS42NzA0IDUuMTk4NiA1Ljc0OTI5IDQuOTk5ODUgNS43NDkyOUM0LjgwMTEgNS43NDkyOSA0LjYxMDQ3IDUuNjcwNCA0LjQ2OTg1IDUuNTI5OTVMMC4yMTk4NDcgMS4yNzk5NUMwLjA4NzM2NjUgMS4xMzc3OCAwLjAxNTI0MzMgMC45NDk3MjkgMC4wMTg2NzE1IDAuNzU1NDI4QzAuMDIyMDk5OCAwLjU2MTEyNyAwLjEwMDgxMiAwLjM3NTc0MSAwLjIzODIyNSAwLjIzODMyOEMwLjM3NTYzOCAwLjEwMDkxNSAwLjU2MTAyMyAwLjAyMjIwMzcgMC43NTUzMjQgMC4wMTg3NzU1QzAuOTQ5NjI1IDAuMDE1MzQ3MiAxLjEzNzY3IDAuMDg3NDcwMiAxLjI3OTg1IDAuMjE5OTVMNC45OTk4NSAzLjkzOTk1TDguNzE5ODUgMC4yMTk5NUM4Ljg2MDQ3IDAuMDc5NDk5NyA5LjA1MTEgMC4wMDA2MTAzNTIgOS4yNDk4NSAwLjAwMDYxMDM1MkM5LjQ0ODYgMC4wMDA2MTAzNTIgOS42MzkyMiAwLjA3OTQ5OTcgOS43Nzk4NSAwLjIxOTk1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==),
    linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.InputSelect_inputSelect__1lfIZ {
  font-size: 13px;
  outline: 0;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 16px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0.3rem 0;
  color: #757575;
  -webkit-appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS43Nzk4NSAwLjIxOTk1QzkuOTIwMyAwLjM2MDU3NiA5Ljk5OTE5IDAuNTUxMiA5Ljk5OTE5IDAuNzQ5OTVDOS45OTkxOSAwLjk0ODcwMSA5LjkyMDMgMS4xMzkzMyA5Ljc3OTg1IDEuMjc5OTVMNS41Mjk4NSA1LjUyOTk1QzUuMzg5MjIgNS42NzA0IDUuMTk4NiA1Ljc0OTI5IDQuOTk5ODUgNS43NDkyOUM0LjgwMTEgNS43NDkyOSA0LjYxMDQ3IDUuNjcwNCA0LjQ2OTg1IDUuNTI5OTVMMC4yMTk4NDcgMS4yNzk5NUMwLjA4NzM2NjUgMS4xMzc3OCAwLjAxNTI0MzMgMC45NDk3MjkgMC4wMTg2NzE1IDAuNzU1NDI4QzAuMDIyMDk5OCAwLjU2MTEyNyAwLjEwMDgxMiAwLjM3NTc0MSAwLjIzODIyNSAwLjIzODMyOEMwLjM3NTYzOCAwLjEwMDkxNSAwLjU2MTAyMyAwLjAyMjIwMzcgMC43NTUzMjQgMC4wMTg3NzU1QzAuOTQ5NjI1IDAuMDE1MzQ3MiAxLjEzNzY3IDAuMDg3NDcwMiAxLjI3OTg1IDAuMjE5OTVMNC45OTk4NSAzLjkzOTk1TDguNzE5ODUgMC4yMTk5NUM4Ljg2MDQ3IDAuMDc5NDk5NyA5LjA1MTEgMC4wMDA2MTAzNTIgOS4yNDk4NSAwLjAwMDYxMDM1MkM5LjQ0ODYgMC4wMDA2MTAzNTIgOS42MzkyMiAwLjA3OTQ5OTcgOS43Nzk4NSAwLjIxOTk1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==),
    linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.InputSelect_invalid__1CL5r {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}
.InputRadio_contentRadio__1eklP {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 12px 0;
  background-color: #f1f1f1;
  position: relative;
  margin: 6px 0;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.InputRadio_contentRadio__1eklP input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.InputRadio_checkmark__139Qw {
  position: relative;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 10px;
}

.InputRadio_contentRadio__1eklP:hover input ~ .InputRadio_checkmark__139Qw {
  background-color: #fff;
}

.InputRadio_contentRadio__1eklP input:checked ~ .InputRadio_checkmark__139Qw {
  background-color: #fff;
  border: 2px solid #ccc;
}

.InputRadio_checkmark__139Qw::after {
  content: '';
  position: absolute;
  display: none;
}

.InputRadio_contentRadio__1eklP input:checked ~ .InputRadio_checkmark__139Qw:after {
  display: block;
}

.InputRadio_contentRadio__1eklP .InputRadio_checkmark__139Qw:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #244af5;
}

.InputRadio_checkLabel__2yxcI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .InputRadio_checkLabel__2yxcI {
    font-size: 13px;
  }
}

.InputCheckBox_contentCheckBox__kEml2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 50px;
}

.InputCheckBox_checkitem__2mqWd {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 220%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.InputCheckBox_checkitem__2mqWd input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.InputCheckBox_checkmark__3L77E {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.InputCheckBox_checkitem__2mqWd:hover input ~ .InputCheckBox_checkmark__3L77E {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
.InputCheckBox_checkitem__2mqWd input:checked ~ .InputCheckBox_checkmark__3L77E {
  background-color: #244af5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.InputCheckBox_checkmark__3L77E:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.InputCheckBox_checkitem__2mqWd input:checked ~ .InputCheckBox_checkmark__3L77E:after {
  display: block;
}

/* Style the checkmark/indicator */
.InputCheckBox_checkitem__2mqWd .InputCheckBox_checkmark__3L77E:after {
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  border: solid white;
  border-width: 2px;
}

.InputDayPicker_inputDayPickerStyle__2bILy {
  border-color: red;
}
.InputDayPicker_contentDatePicker__2UrAY {
  width: 100%;
}

.InputDayPicker_styleIcon__1OBLQ {
  margin-top: 17px;
  margin-left: 19px;
  position: absolute;
  font-size: 15px;
  color: #244af5;
}

.InputIcon_inputIcon__1-Fov {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #747474;
  width: 20rem;
  padding: 1rem 3rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  box-sizing: border-box;
}

.InputIcon_inputContainer__1HbVp {
  width: 100%;
}

.InputIcon_iconStyle__3ZbFQ {
  position: absolute;
  padding: 18px;
  font-size: 13px;
  color: #696969;
}
.InputIcon_invalid__SlIOR {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}

.PaymentStripe_cardImages__2VDZo {
  padding: 1rem 0;
  text-align: center;
}

.PaymentStripe_cardImages__2VDZo img {
  padding: 0 0.5rem;
}

.PaymentStripe_containerForm__2SfWa {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
}

.PaymentStripe_formFields__OjfZk {
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-column-gap: 20px;
}

.PaymentStripe_fullRow__1aJTq {
  grid-column: 1/3;
  width: 300px;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.PaymentStripe_inputForm__1gua5 {
  font-size: 15px;
  outline: 0;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  border-radius: 27.5px;
  padding: 19px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  margin: 0.5rem 0;
}

.PaymentStripe_groupForm__1pUXO {
  position: relative;
}

.PaymentStripe_iconHelp___J-Ye {
  position: absolute;
  right: 21px;
  top: 25px;
  color: #757575;
  font-size: 19px;
}

.PaymentStripe_msgError__uziZR {
  font-weight: 500;
  font-size: 14px;
  color: #cd1414;
  border: solid 1px #cd1414;
  width: 500px;
  margin: 1rem auto 0 auto;
  border-radius: 19px;
  padding: 0.4rem;
  background-color: #fff9f9;
}

@media (min-width: 768px) {
  .PaymentStripe_containerForm__2SfWa {
    padding: 0 1rem;
  }

  .PaymentStripe_formFields__OjfZk {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.HolderForm_formFields__28BKz {
  max-width: 100%;
  margin: 0 auto;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-column-gap: 20px;
}

.HolderForm_oneColumnForm__1fUrp {
  max-width: 440px;
  margin: 0 auto;
}

.HolderForm_formatTextDescription__1DAua {
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
  padding-bottom: 1rem;
  padding-left: 2rem;

}

@media (min-width: 768px) {
  .HolderForm_formFields__28BKz {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.EntityForm_formFields__1qoVJ {
  max-width: 100%;
  margin: 0 auto;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-column-gap: 20px;
}

.EntityForm_oneColumnForm__2yC2I {
  max-width: 440px;
  margin: 0 auto;
}

.EntityForm_formatTextDescription__1_bc3 {
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  padding-bottom: 1rem;
  padding-left: 2rem;
}

@media (min-width: 768px) {
  .EntityForm_formFields__1qoVJ {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.EntityTemplate_containerCollap__1zqDm {
  margin-bottom: 1rem;
}

.EntityTemplate_collapsible__1qoH0 {
  font-family: Montserrat;
  font-weight: 600;
  background-color: #f1f1f1;
  color: #000000;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  border: none;
  text-align: left;
  line-height: 150%;
  font-size: 12px;
}

.EntityTemplate_active__2oDEW,
.EntityTemplate_collapsible__1qoH0:hover {
  background-color: #e9e5e5;
}

.EntityTemplate_collapsible__1qoH0:after {
  content: "\2212";
  color: #000000;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.EntityTemplate_active__2oDEW:after {
  content: "\002B";
}

.EntityTemplate_content__3ZMFY {
  padding: 0 18px;
  max-height: null;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
}

.EntityTemplate_labelEntity__1muLb {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.EntityTemplate_itemEntity__3RpBi {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.EntityTemplate_contentButton__2pE3o{
  width: 300px;
  margin: 1rem auto 2rem auto;
}

.EntityTemplate_modalBtn__1HnCh{
  width: 250px;
  padding: .5rem .5rem;
}

.EntityTemplate_modalCtrl__RBvxv{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.ConfirmationTemplate_confirmation__xA_vR {
  padding: 0;
  width: 100%;
}

.ConfirmationTemplate_confirmationItem__2y2OK {
  padding: 0.3rem 0;
}

.ConfirmationTemplate_confirmationItem__2y2OK h3 {
  color: #304aeb;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
}

.ConfirmationTemplate_confirmationItem__2y2OK p {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.ConfirmationTemplate_textDescription__Zw1EX{
  line-height: 150%;
  margin-bottom: 4rem;
}

.ConfirmationTemplate_termsAndConditions__1Qfj0 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 50px;
}

.ConfirmationTemplate_linkText__30hWj {
  color: #304aeb;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
}

.ConfirmationTemplate_confirmation__xA_vR li {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  list-style: none;
}

.ConfirmationTemplate_total__3VZxZ {
  font-weight: 600;
  line-height: 150%;
  font-size: 23px;
}

.ConfirmationTemplate_currency__zdYhG {
  font-weight: 500;
  font-size: 16px;
  color: #304aeb;
}

.ConfirmationTemplate_terms__2E4_R {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}
.ConfirmationTemplate_linkterms__iRcnI {
  font-weight: bold;
}

.ConfirmationTemplate_btnConfirm__ByP8G {
  text-align: center;
  width: 300px;
  margin: 0 auto;
}

.ConfirmationTemplate_labelEntity__2nsUx{
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.ConfirmationTemplate_itemEntity__22If0{
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

/* Customize the label (the container) */
.ConfirmationTemplate_checkitem__3BVuv {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 220%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ConfirmationTemplate_checkitem__3BVuv input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ConfirmationTemplate_checkmark__36IXS {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.ConfirmationTemplate_checkitem__3BVuv:hover input ~ .ConfirmationTemplate_checkmark__36IXS {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
.ConfirmationTemplate_checkitem__3BVuv input:checked ~ .ConfirmationTemplate_checkmark__36IXS {
  background-color: #244af5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ConfirmationTemplate_checkmark__36IXS:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ConfirmationTemplate_checkitem__3BVuv input:checked ~ .ConfirmationTemplate_checkmark__36IXS:after {
  display: block;
}

/* Style the checkmark/indicator */
.ConfirmationTemplate_checkitem__3BVuv .ConfirmationTemplate_checkmark__36IXS:after {
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  border: solid white;
  border-width: 2px;
}

@media (min-width: 768px) {
  .ConfirmationTemplate_confirmation__xA_vR {
    padding: 1rem;
  }
}

.CoauthorTemplate_contentRadioSelect__i_hYC {
  width: 440px;
  margin: 0 auto;
  text-align: center;
}

.CoauthorTemplate_contentBtnDelete__15X0w {
  width: 172px;
  margin: 1rem auto;
}
.CoauthorTemplate_containerPLine__uUuvB{
  margin: 1rem 0 1.5rem 0;
  box-sizing: border-box;
}
.main-container {
  min-height: 100vh;
}
.main {
  overflow: auto;
  padding-bottom: 65px;
}

@media (min-width: 768px) {
  .main {
    padding-bottom: 120px;
  }
}


.Dashboard_containerMenuDash__1Oghh {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding-bottom: 4rem;

  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 768px) {
  .Dashboard_containerMenuDash__1Oghh {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

/* .containerMenuDash {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 5rem;
}

@media (min-width: 375px) {
  .containerMenuDash {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .containerMenuDash {
    width: 800px;
    grid-template-columns: 1fr 1fr;
  }
} */

.link-back {
  color: #0f0f0f;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  box-sizing: border-box;
  cursor: pointer;
}

.section-title {
  text-align: center;
}

.item-marcas {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 1rem;
}

.item-marcas > img {
  vertical-align: middle;
}

.text-description {
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 2rem;
}

.subtitle-modle {
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
}

.price {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  margin-top: 0;
}

.currency {
  color: #244af5;
  font-size: 15px;
}

.lablel-price {
  margin: 0;
}

.content-btn {
  text-align: center;
  width: 300px;
  margin: 0 auto;
}

.content-btn-back {
  margin-top: 4rem;
}

.RegistroMarca_contentRadioSelect__1VMEv {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.RegistroMarca_formElement__1bkpn {
  width: 100%;
  margin: 0 auto;
}

.RegistroMarca_contentButtonNext__3MPcn {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.RegistroMarca_marginTextDescription__2PHl9 {
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 2rem;
  margin: 0;
  text-align: justify;
}

@media (min-width: 768px) {
  .RegistroMarca_marginTextDescription__2PHl9 {
    margin: 0 1.5rem;
  }

  .RegistroMarca_contentRadioSelect__1VMEv {
    width: 440px;
  }
}

.HolderSelect_descriptionSub__2npP4 {
  padding: 0 1rem 0.5rem 2rem;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
}

.HolderSelect_radioOptionBrandRegister__3aqrD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 5px;
  gap: 5px;
  background-color: #f1f1f1;
  width: 310px;
  margin: 10px auto;
  text-align: left;
  padding: 1rem;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
}

.HolderSelect_contentSelect__3A9-W {
  max-width: 310px;
  margin: 0 auto;
}

.HolderSelect_contentForm__1prEi {
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.HolderSelect_contentButton__p-qDw {
  width: 300px;
  margin: 0 auto;
  padding: 2rem 0 3rem 0;
  text-align: center;
}
.HolderSelect_contentRadioSelect__3Pk5m {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .HolderSelect_contentRadioSelect__3Pk5m{
    width: 440px;
  }
}

.RegisterTradeMark_contentRadioSelect__4i4IC {
  max-width: 440px;
  margin: 0 auto;
  text-align: center;
}

.RegisterTradeMark_contentForm__3YKfk {
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.RegisterTradeMark_contentButton__2nlBj {
  width: 300px;
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.add-controls {
  padding-top: 0.8rem;
}

.add-control {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 185px 2fr;
}

.icon-add-control {
  justify-self: end;
}

.container-controls {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.container-desc-controls {
  margin: 0;
}

.radio-option-controls {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 300px;
  margin: 0 auto;
}

.radio-option-brand {
  background-color: #f1f1f1;
  width: 218px;
  margin: 10px auto;
  text-align: left;
  padding: 1rem;
}

.updaload-file {
  width: 100%;
  text-align: center;
  /* margin-bottom: 2.5rem; */
}

.dateUseBrand {
  width: 270px;
  margin: 5px auto;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  box-sizing: border-box;
}
.desDatePicker{
  margin-bottom: 5px;
}

.classes-description {
  padding: 0 1rem 0.5rem 1rem;
}

.name-file-added {
  font-weight: bold;
}

.title-select {
  font-size: 18px;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 0.8rem;
  margin: 0;
}

.form-informacion-marca {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: column;
          flex-wrap: column;
}

.form-informacion-marca > div {
  width: 100%;
  padding: 0 0.3rem;
}
.radio-button-next {
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 4rem;
}

@media (min-width: 375px) {
  .radio-option-brand {
    width: 270px;
  }
  .add-control {
    grid-template-columns: 254px 2fr;
  }
}

@media (min-width: 768px) {
  .form-informacion-marca {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: row;
            flex-wrap: row;
  }

  .add-control {
    grid-template-columns: 340px 2fr;
  }
  .container-controls {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }

  .container-desc-controls {
    margin: 0 1.5rem;
  }
}

.CheckOut_title__2osv4 {
  font-size: 25px;
  font-weight: bold;
  line-height: 150%;
  text-align: center;
}

.CheckOut_description__2gKTt {
  font-size: 16px;
  font-weight: 200;
  line-height: 150%;
}

.Confirmation_description__gfkcw {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.RegStdMusicalGroups_cntButton__WT-u4 {
    max-width: 302px;
    margin: 0 auto;
    padding-bottom: 40px;
}
.RegMusicalGroup_contentRadioSelect__30xV_ {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.RegMusicalGroup_contentButtonNext__Fgkro {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.RegMusicalGroup_contentButton__2FwxU {
  width: 300px;
  padding-bottom: 3rem;
  padding-top: 1rem;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .RegMusicalGroup_marginTextDescription__18Rs_ {
    margin: 0 1.5rem;
  }

  .RegMusicalGroup_contentRadioSelect__30xV_ {
    width: 440px;
  }
}

.RegStdMagazie_cntButton__2NKcp {
    max-width: 302px;
    margin: 0 auto;
    padding-bottom: 40px;
}
.RegMagazines_contentRadioSelect__2RmDY {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .RegMagazines_contentButtonNext__37k9X {
    width: 300px;
    margin: 0 auto 2rem auto;
  }
  
  .RegMagazines_contentButton__DUEbt {
    width: 300px;
    padding-bottom: 3rem;
    padding-top: 1rem;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    .RegMagazines_marginTextDescription__11rs9 {
      margin: 0 1.5rem;
    }
  
    .RegMagazines_contentRadioSelect__2RmDY {
      width: 440px;
    }
  }
  
.RegStdNewspaper_cntButton__3WYOy {
    max-width: 302px;
    margin: 0 auto;
    padding-bottom: 40px;
}
.RegNewspaper_contentRadioSelect__2NGL4 {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .RegNewspaper_contentButtonNext__3bd6y {
    width: 300px;
    margin: 0 auto 2rem auto;
  }
  
  .RegNewspaper_contentButton__2iP88 {
    width: 300px;
    padding-bottom: 3rem;
    padding-top: 1rem;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    .RegNewspaper_marginTextDescription__NDmF6 {
      margin: 0 1.5rem;
    }
  
    .RegNewspaper_contentRadioSelect__2NGL4 {
      width: 440px;
    }
  }
.RegStdPublications_cntButton__3gCfL {
    max-width: 302px;
    margin: 0 auto;
    padding-bottom: 40px;
}
.RegPublications_contentRadioSelect__3VZKR {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .RegPublications_contentButtonNext__lGTN1 {
    width: 300px;
    margin: 0 auto 2rem auto;
  }
  
  .RegPublications_contentButton__3sf0j {
    width: 300px;
    padding-bottom: 3rem;
    padding-top: 1rem;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    .RegPublications_marginTextDescription__1yy93 {
      margin: 0 1.5rem;
    }
  
    .RegPublications_contentRadioSelect__3VZKR {
      width: 440px;
    }
  }
  
.RegistrationObras_descriptionFile__1dvm5 {
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  width: 300px;
  margin: 0 auto;
}

.RegistrationObras_contentRadioSelect__3ZWBj {
  width: 440px;
  margin: 0 auto;
  text-align: center;
}
.RegistrationObras_descriptionSub__SBmhj {
  padding: 0 1rem 0.5rem 2rem;
  line-height: 150%;
}
.RegistrationObras_contentButton__3iLJg {
  padding: 2rem 0;
  text-align: center;
}

.RegistrationObras_btnNex___QW4X {
  width: 300px;
  margin: 0 auto;
}

.RegistrationObras_titleModule__xDvsp {
  font-size: 18px;
  font-weight: bold;
}

.RegistrationObras_containerModule__1Mniy {
  width: 100%;
}

.RegistrationObras_containerPLine__3axRV{
  margin-top: 1.6rem ;
}

.CheckOutObras_title__1vNTf {
    font-size: 25px;
    font-weight: bold;
    line-height: 150%;
    text-align: center;
  }
  
  .CheckOutObras_description__2i0I8 {
    font-size: 16px;
    font-weight: 200;
    line-height: 150%;
  }
  
.FileModal_backDrop__3uc5V {
  display: none;
  position: fixed;
  z-index: 100;
  padding-top: 2rem;
  left: 0;
  top: 3.1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(252, 252, 252, 0.884);
}

.FileModal_closeModal__3WRH6 {
  display: none;
}

.FileModal_openModal__3p-eM {
  display: block;
}

.FileModal_customModal__57j7F {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  width: 88%;
  height: 80%;
}

.FileModal_close__1yuhM {
  float: right;
  cursor: pointer;
}



.IndustrialDesigns_contentRadioSelect__2ymHN {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.IndustrialDesigns_contentButton__fiL7j{
  padding-bottom: 15px;
}

.IndustrialDesigns_formElement__3QEIt {
  width: 100%;
  margin: 0 auto;
}

.IndustrialDesigns_contentButtonNext__ULi1j {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.IndustrialDesigns_marginTextDescription__3p1dD {
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 2rem;
  margin: 0;
  text-align: justify;
}

@media (min-width: 768px) {
  .IndustrialDesigns_marginTextDescription__3p1dD {
    margin: 0 1.5rem;
  }

  .IndustrialDesigns_contentRadioSelect__2ymHN {
    width: 440px;
  }
}

.grid-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  padding: 1rem 10px;
  margin-bottom: 5rem;
  max-width: 49.6rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  
}

.user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 20px 0 0 0;
}

.user-mail {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-decoration: none;
}

.grid-item {
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 4%);
  padding: 1rem 1rem;
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  height: 6.4rem;
}

.grid-item > p {
  justify-self: left;
  padding-left: 1rem;
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.item-label > a {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}

@media (min-width: 768px) {
  .grid-container {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 2rem;
    margin-bottom: 3rem;
  }

  .grid-item {
    padding: 1rem 2rem;
    grid-template-columns: 1fr 4fr 0.5fr;
  }
}

.SimpleModal_modal__3ZDFB {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.644);
}

.SimpleModal_modalContent__39hFa {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  margin-bottom: 9rem;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: SimpleModal_animatetop__3GPeW;
  -webkit-animation-duration: 0.4s;
  animation-name: SimpleModal_animatetop__3GPeW;
  animation-duration: 0.4s;
}

@-webkit-keyframes SimpleModal_animatetop__3GPeW {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes SimpleModal_animatetop__3GPeW {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.SimpleModal_close__2SKX9 {
  color: rgb(78, 78, 78);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.SimpleModal_close__2SKX9:hover,
.SimpleModal_close__2SKX9:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.SimpleModal_modalHeader__DonMh {
  font-size: 11px;
  padding: 2px 16px;
}

.SimpleModal_modalBody__3WfOg {
  padding: 2px 16px;
}

@media (min-width: 768px) {
  .SimpleModal_modalHeader__DonMh {
    font-size: 16px;
    padding: 2px 16px;
  }
  .SimpleModal_modalContent__39hFa {
    width: 634px;
  }

  .SimpleModal_modal__3ZDFB {
    padding-top: 150px;
    height: 100%;
  }
}

.BusinessNameTemplate_containerCollap__2H0zk {
  margin-bottom: 1rem;
}

.BusinessNameTemplate_collapsible__3VtYa {
  font-family: Montserrat;
  font-weight: 600;
  background-color: #f1f1f1;
  color: #000000;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  border: none;
  text-align: left;
  line-height: 150%;
  font-size: 12px;
}

.BusinessNameTemplate_selected__v1AVs {
  background-color: #304AEB !important;
  color: white !important;
  font-weight: bold;
}

.BusinessNameTemplate_active__2AvZN,
.BusinessNameTemplate_collapsible__3VtYa:hover {
  background-color: #e9e5e5;
}

.BusinessNameTemplate_collapsible__3VtYa:after {
  content: "\2212";
  color: #000000;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.BusinessNameTemplate_active__2AvZN:after {
  content: "\002B";
}

.BusinessNameTemplate_content__f4emG {
  padding: 0 18px;
  max-height: null;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
}

.BusinessNameTemplate_labelEntity__3BP_3 {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.BusinessNameTemplate_itemEntity__yxLoF {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.BusinessNameTemplate_contentButton__2unhT {
  width: 300px;
  margin: 1rem auto 2rem auto;
}

.BusinessNameTemplate_modalBtn__3O1r7 {
  width: 250px;
  padding: 0.5rem 0.5rem;
}

.BusinessNameTemplate_modalCtrl__11RYA {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.FormBusinessName_formFields__2trEO {
    max-width: 100%;
    margin: 0 auto;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    grid-column-gap: 20px;
}

.FormBusinessName_btnForm__3dA0J {
    width: 300px;
    margin: 0 auto;
  }

@media (min-width: 768px) {
    .FormBusinessName_formFields__2trEO {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  

.Collapsible_containerCollap__3M-5C {
  margin-bottom: 1rem;
}

.Collapsible_collapsible__25KZ3 {
  font-family: Montserrat;
  font-weight: 600;
  background-color: #f2f2f2;
  color: #000000;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  border: none;
  text-align: left;
  line-height: 150%;
  font-size: 12px;
}

.Collapsible_content__38akw {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f2f2f2;
  padding: 0.5rem 2rem;
}

.Collapsible_contentComments__26-7w {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
  padding: 0.5rem 2rem;
}

.Collapsible_labelEntity__3BLzI {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.Collapsible_itemEntity__3HaDw {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.Collapsible_contentButton__2JPLl {
  width: 300px;
  margin: 1rem auto 2rem auto;
}

.Collapsible_modalBtn__23qXL {
  width: 250px;
}

.Collapsible_modalCtrl__1nSHM {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.Collapsible_title__3Z12q {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
}

.Collapsible_collapsibleIcon__1vMsD {
  margin-left: auto;
}

.Collapsible_limitDate__2jTsT {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.Collapsible_contentInformation__12ZsZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Collapsible_labelItem__13lVv {
  min-width: 400px;
}

.Collapsible_labelItem__13lVv h3 {
  color: #304aeb;
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
}

.Collapsible_labelItem__13lVv p {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.Collapsible_actions__3C5PD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 1rem 0;
  -webkit-align-items: center;
          align-items: center;
}

.Collapsible_actions__3C5PD > div:first-child {
  margin-bottom: 1rem;
}
.Collapsible_actions__3C5PD > div {
  margin-left: 0rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .Collapsible_contentInformation__12ZsZ {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .Collapsible_actions__3C5PD {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 1rem 0;
  }

  .Collapsible_actions__3C5PD > div {
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .Collapsible_actions__3C5PD > div:first-child {
    margin: 0;
  }
}

.StatusBox_statusContainer__1nvq8 {
  padding: 0.5rem 1rem;
}

.StatusBox_title__27YOz {
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.05em;
}

.StatusBox_status__1GPin {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  padding-top: 0.2rem;
}

.StatusBox_icon__K2IKw {
  padding-right: 0.5rem;
}

.StatusBox_new__JK4zU {
  background: linear-gradient(90deg, #872a96 0%, #e73289 100%);
  color: #ffffff;
}

.StatusBox_processed__1awds {
  background: linear-gradient(90deg, #5ff965 0%, #fcf200 100%);
}

.StatusBox_impediment__1mMc- {
  background: linear-gradient(90deg, #ff003d 0%, #a80000 100%);
  color: #ffffff;
}

.StatusBox_requiredFiles__8eQLl {
  background: linear-gradient(90deg, #ffe600 0%, #ff5c00 100%);
}

.StatusBox_inProcess__2Dpyy {
  background: linear-gradient(90deg, #6e00a2 0%, #00b3da 100%);
  color: #ffffff;
}

.MyRequest_headerSection__3rOPM {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}
.MyRequest_headerLabel__3Ax4i {
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #304aeb;
}

.MyRequest_headerService__c0kf_ {
  font-weight: bold;
  font-size: 25px;
  line-height: 150%;
}

.MyRequest_commentsSection__-Xa3L {
  padding: 1rem 0;
}

.MyRequest_commentDate__11bu3 {
  font-weight: bold;
  font-size: 15px;
  line-height: 120%;
  padding: 0.5rem 0;
}

.MyRequest_comment__1QbWg {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.MyRequest_infoBrand__Bq_o0 {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 0;
}

.MyRequest_detailBrandSection__24DuG {
  padding-bottom: 1rem;
}

.MyRequest_sectionInfo__102qp {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.MyRequest_itemInfo__B112I {
  text-align: left;
  padding-bottom: 1rem;
}

.MyRequest_itemHead__3KCxX {
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  color: #304aeb;
}

.MyRequest_itemData__3YPSv {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.MyRequest_entityData__1ED-l {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: left;
}

.MyRequest_infoBrandFull__3sfyc {
  text-align: left;
}

.MyRequest_labelEntity__3duej {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding-top: 10px;
  padding-bottom: 5px;
}

.MyRequest_itemEntity__1fptr {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  padding-bottom: 10px;
}

.MyRequest_fileItem__2vwGp {
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
}

.MyRequest_itemClass__3d_58 {
  margin: 1rem 1rem 1rem 0;
  padding: 1rem;
  background: #f2f2f2;
}

.MyRequest_sectionClass__1dLG- {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;
}

.MyRequest_classId__2QgaD {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  padding: 0.5rem 0;
}

.MyRequest_headerSectionComponent__3NZFz {
  text-align: center;
  padding: 1rem 0;
}

.MyRequest_contentButton__CPZ-G {
  width: 300px;
  margin: 0.5rem auto;
}

/* Modal */
.MyRequest_mainContentModal__2lRW0 {
  width: 100%;
}

.MyRequest_descriptionModal__8ZcSp {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.MyRequest_subTitleModal__2wq0H {
  font-weight: bold;
  font-size: 15px;
  line-height: 120%;
  padding: 1rem 0;
}

.MyRequest_modalCtrl__Dpy6t {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.MyRequest_modalConrolsHeader__1PVrB {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MyRequest_modalBtn__1-1ZE {
  width: 250px;
  padding: 0.5rem 0.5rem;
}

/* FORM NEW BUSINESS NAME*/
.MyRequest_formFields__14A6C {
  max-width: 100%;
  margin: 0 auto;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-column-gap: 20px;
}

.MyRequest_paymentContent__8-40x {
  text-align: left;
}

.MyRequest_paymentSuccessHeader__2Tbk1 {
  background-color: #63ab62;
  color: aliceblue;
  padding: 10px;
  font-weight: bold;
}

.MyRequest_paymentRjected__3yjgI {
  background-color: #C14D4D;
  color: aliceblue;
  padding: 10px;
  font-weight: bold;
}
.MyRequest_contentPaymentSuccess__QqREV {
  background-color: #e5f2d5;
  color: #37580e;
  padding: 10px;
  font-weight: bold;
}

.MyRequest_contentPaymentRejected__qBIsu {
  background-color: #E9D6D6;
  color: #780D0D;
  padding: 10px;
  font-weight: bold;
}
.MyRequest_dataPayment__3ul8b {
  font-weight: normal;
}

@media (min-width: 768px) {
  .MyRequest_sectionClass__1dLG- {
    grid-template-columns: repeat(2, 1fr);
  }
  .MyRequest_formFields__14A6C {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .MyRequest_infoBrand__Bq_o0 {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1rem;
  }
}

.receipt_textSubtitle__5wJss {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.receipt_confirmationNumber__3sNqH {
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #44d8b9;
}

.receipt_description__13Yd5 {
  padding: 2rem 1rem;
  line-height: 150%;
}

.receipt_contentCenter__29dxk {
  text-align: center;
}

.receipt_sincerely__2BcCP {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.receipt_team__2b24r {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.receipt_textBlue__3iq3_ {
  color: #244af5;
  font-weight: bold;
}

.receipt_btnSuccess__3_tV4 {
  width: 300px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .receipt_description__13Yd5 {
    padding: 2rem 4rem;
  }
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 9999;
    transition: 1s all;
    opacity: 0;
}
.loading.show {
    opacity: 1;
}
.loading .spin {
    border: 3px solid hsla(185, 100%, 62%, 0.2);
    border-top-color: #304AEB;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}   
button {
  box-shadow: none !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.container-input-form {
  width: 100%;
}

.container-text-description {
  margin-bottom: 2rem;
}

.container-input-text-description {
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.form-fields {
  display: -webkit-flex;
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-column-gap: 20px;
}

.sub-description {
  padding: 0 1rem 0.5rem 2rem;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
}

.parting-line {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    93.52deg,
    #304aeb 12.6%,
    #00b9c5 65.73%,
    #73edb0 111.65%
  );
  /* margin-bottom: 1rem; */
}

.main-content-form {
  max-width: 440px;
  margin: 0 auto;
}

.container-simple {
  margin-top: 5rem;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
}

.container-select {
  width: 440px;
  margin: 0 auto;
  text-align: center;
}

.container-modulo {
  text-align: left;
  max-width: 900px;
  margin: 5rem auto;
  padding: 0 1rem;
}

.btn-success {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  width: 15rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 10px;
  border-radius: 10px;
  background: linear-gradient(
    93.52deg,
    #304aeb 12.6%,
    #00b9c5 65.73%,
    #73edb0 111.65%
  );
  box-shadow: 0px 1px 1px rgba(48, 74, 235, 0.1);
  box-sizing: border-box;
}

.btn-danger {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  width: 14rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 10px;
  border-radius: 10px;
  background: linear-gradient(92.11deg, #eb6767 1.89%, #6815d2 100%);
  box-shadow: 0px 1px 1px rgba(48, 74, 235, 0.1);
  box-sizing: border-box;
}

.btn-round {
  background-color: #304aeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 10px 1.5rem;
  border: none;
  cursor: pointer;
}

.btn-round:focus {
  outline: none;
}

.subtitle {
  padding-top: 1rem;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: #000000;
}

.subtitle-medium {
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  text-transform: uppercase;
  padding: 1.5rem;
  color: #000000;
}

.subtitle-small {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-transform: uppercase;
  padding-bottom: 0.7rem;
  color: #5e5e5e;
}

.subtitle-section {
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
}

.assets-box {
  padding-top: 1rem;
  width: 80%;
  margin: 0 auto;
}

.btn-marging {
  margin: 2rem 0 1.2rem 0;
}

.btn-back-margin {
  margin: 2rem auto;
}

.label-information {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  width: 100%;
  left: calc(-50vw + 50%);
  background-color: #f1f1f1;
  padding: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 12px;
}

.label-information > div {
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
}

.square-round-blue {
  cursor: pointer;
  background-color: #304aeb;
  margin: 10px auto;
  padding: 20px 16px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 30px;
  max-width: 440px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 150%;
  text-align: left;
}

.square-round-green {
  cursor: pointer;
  background-color: #44d8b9;
  margin: 10px auto;
  padding: 14px 36px;
  font-weight: normal;
  border-radius: 30px;
  max-width: 440px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 150%;
  text-align: left;
}

.input-form-field {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 16rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

.input-form-field:focus {
  border: 1px solid #99a0c1;
  border-radius: 27.5px;
  outline: none;
}

.input-select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS43Nzk4NSAwLjIxOTk1QzkuOTIwMyAwLjM2MDU3NiA5Ljk5OTE5IDAuNTUxMiA5Ljk5OTE5IDAuNzQ5OTVDOS45OTkxOSAwLjk0ODcwMSA5LjkyMDMgMS4xMzkzMyA5Ljc3OTg1IDEuMjc5OTVMNS41Mjk4NSA1LjUyOTk1QzUuMzg5MjIgNS42NzA0IDUuMTk4NiA1Ljc0OTI5IDQuOTk5ODUgNS43NDkyOUM0LjgwMTEgNS43NDkyOSA0LjYxMDQ3IDUuNjcwNCA0LjQ2OTg1IDUuNTI5OTVMMC4yMTk4NDcgMS4yNzk5NUMwLjA4NzM2NjUgMS4xMzc3OCAwLjAxNTI0MzMgMC45NDk3MjkgMC4wMTg2NzE1IDAuNzU1NDI4QzAuMDIyMDk5OCAwLjU2MTEyNyAwLjEwMDgxMiAwLjM3NTc0MSAwLjIzODIyNSAwLjIzODMyOEMwLjM3NTYzOCAwLjEwMDkxNSAwLjU2MTAyMyAwLjAyMjIwMzcgMC43NTUzMjQgMC4wMTg3NzU1QzAuOTQ5NjI1IDAuMDE1MzQ3MiAxLjEzNzY3IDAuMDg3NDcwMiAxLjI3OTg1IDAuMjE5OTVMNC45OTk4NSAzLjkzOTk1TDguNzE5ODUgMC4yMTk5NUM4Ljg2MDQ3IDAuMDc5NDk5NyA5LjA1MTEgMC4wMDA2MTAzNTIgOS4yNDk4NSAwLjAwMDYxMDM1MkM5LjQ0ODYgMC4wMDA2MTAzNTIgOS42MzkyMiAwLjA3OTQ5OTcgOS43Nzk4NSAwLjIxOTk1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==),
    linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.description-file {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3f3f3f;
}

.description-confirm {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.description {
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05rem;
  padding: 0 1rem 1rem 1rem;
}
a:link,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  color: #121f61 !important;
  text-decoration: none !important;
}

.btn-round-blue {
  background-color: #304aeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 5px 0.4rem;
  border: none;
  cursor: pointer;
}

.DayPickerInput {
  display: inline-block;
  width: 100%;
}

.DayPickerInput input {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none;
  font-size: 14px;
  width: 15.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: center;
  box-sizing: border-box;
}

.DayPickerInput input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.description-file {
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  width: 300px;
  margin: 0 auto;
}

.descripction-text {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.descripction-action {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3f3f3f;
  padding: 0 1rem;
}

.content-form-main {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.contentainer-button {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.contentainer-description-text {
  width: 100%;
  margin: 0 auto;
}

.text-blue {
  color: #304aeb;
  font-weight: bold;
}

.erros-form {
  color: #d73232;
  font-size: 12px;
  padding-left: 30px;
}

.confirmation-item {
  padding: 0.3rem 1.5rem;
}

.confirmation-item h3 {
  color: #304aeb;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
}

.confirmation-item p {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.ammount {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.total {
  font-weight: 600;
  line-height: 150%;
  font-size: 23px;
}

.currency {
  font-weight: 500;
  font-size: 16px;
  color: #304aeb;
}

.description-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  padding-bottom: 1rem;
}
@media (min-width: 375px) {
  .DayPickerInput input {
    font-size: 13px;
    width: 100%;
  }
  .btn-danger {
    width: 20rem;
  }
  .square-round-blue {
    font-size: 13px;
  }
  .input-form-field {
    width: 20rem;
  }
}

@media (min-width: 768px) {
  .btn-round-blue {
    padding: 10px 1.5rem;
  }
  .square-round-blue {
    padding: 20px 40px;
    font-size: 13px;
  }
  .input-form-field {
    width: 21rem;
  }
  .label-information {
    width: 100%;
    left: 0;
    padding: 1rem;
    font-size: 14px;
  }

  .container-modulo {
    padding: 0 1rem;
  }

  .description {
    font-size: 15px;
    padding: 0 2rem 2rem 2rem;
  }

  .auth {
    width: 80%;
    margin: 1.2rem auto;
    padding: 7rem;
  }

  .btn-success {
    width: 20rem;
  }

  .subtitle {
    padding-top: 2rem;
    font-size: 22px;
  }

  .subtitle-small {
    padding-bottom: 1.5rem;
  }

  .assets-box {
    padding-top: 3rem;
  }

  .container-modulo {
    text-align: left;
    max-width: 49rem;
    margin: 5rem auto;
    padding: none;
  }

  .content-form-main {
    width: 440px;
  }
}

