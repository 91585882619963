.serviciosButton {
  flex-basis: 100%;
  height: 6.25rem;
  margin: 0 1% 1rem 1%;
  padding: 1rem 3rem 1rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  text-align: left;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: bold;
  background-color: white;
  color: black;
  background-image: url('../../../assets/images/icons/row-right.svg');
  background-position: right 1rem center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0);
  transition: box-shadow 0.4s;
  outline: none;
  border: none;
}

.serviciosButton:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  color: #304aeb !important;
}

.serviciosButton img {
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .serviciosButton {
    flex-basis: 48%;
    max-width: 25rem;
  }
}
