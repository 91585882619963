.linkBack {
  color: #0f0f0f;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  box-sizing: border-box;
  cursor: pointer;
}

.iconBack {
  padding-right: 15px;
}
