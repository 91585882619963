.containerCollap {
  margin-bottom: 1rem;
}

.collapsible {
  font-family: Montserrat;
  font-weight: 600;
  background-color: #f2f2f2;
  color: #000000;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  border: none;
  text-align: left;
  line-height: 150%;
  font-size: 12px;
}

.content {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f2f2f2;
  padding: 0.5rem 2rem;
}

.contentComments {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
  padding: 0.5rem 2rem;
}

.labelEntity {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.itemEntity {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.contentButton {
  width: 300px;
  margin: 1rem auto 2rem auto;
}

.modalBtn {
  width: 250px;
}

.modalCtrl {
  display: flex;
  width: 100%;
  justify-content: center;
}

.title {
  display: flex;
  gap: 1rem;
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
}

.collapsibleIcon {
  margin-left: auto;
}

.limitDate {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.contentInformation {
  display: flex;
  flex-direction: column;
}

.labelItem {
  min-width: 400px;
}

.labelItem h3 {
  color: #304aeb;
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
}

.labelItem p {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.actions {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  align-items: center;
}

.actions > div:first-child {
  margin-bottom: 1rem;
}
.actions > div {
  margin-left: 0rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .contentInformation {
    flex-direction: row;
  }

  .actions {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
  }

  .actions > div {
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .actions > div:first-child {
    margin: 0;
  }
}
