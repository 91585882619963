.cardImages {
  padding: 1rem 0;
  text-align: center;
}

.cardImages img {
  padding: 0 0.5rem;
}

.containerForm {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
}

.formFields {
  justify-content: space-around;
  grid-column-gap: 20px;
}

.fullRow {
  grid-column: 1/3;
  width: 300px;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.inputForm {
  font-size: 15px;
  outline: 0;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  border-radius: 27.5px;
  padding: 19px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  margin: 0.5rem 0;
}

.groupForm {
  position: relative;
}

.iconHelp {
  position: absolute;
  right: 21px;
  top: 25px;
  color: #757575;
  font-size: 19px;
}

.msgError {
  font-weight: 500;
  font-size: 14px;
  color: #cd1414;
  border: solid 1px #cd1414;
  width: 500px;
  margin: 1rem auto 0 auto;
  border-radius: 19px;
  padding: 0.4rem;
  background-color: #fff9f9;
}

@media (min-width: 768px) {
  .containerForm {
    padding: 0 1rem;
  }

  .formFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
