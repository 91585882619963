.title {
    font-size: 25px;
    font-weight: bold;
    line-height: 150%;
    text-align: center;
  }
  
  .description {
    font-size: 16px;
    font-weight: 200;
    line-height: 150%;
  }
  