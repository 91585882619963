.groupForm {
  position: relative;
}

.floatLabel {
  font-size: 13px;
  position: absolute;
  top: 8px;
  left: 10px;
  color: #757575;
  padding-left: 1rem;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;
}

.inputActive {
  color: #304aeb;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  transform: translate(0, 10px) scale(0.75);
}

.inputForm {
  font-size: 13px;
  outline: 0;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 23px 22px 10px 22px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0;
}

.invalid {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}
