.inputIcon {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #747474;
  width: 20rem;
  padding: 1rem 3rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  box-sizing: border-box;
}

.inputContainer {
  width: 100%;
}

.iconStyle {
  position: absolute;
  padding: 18px;
  font-size: 13px;
  color: #696969;
}
.invalid {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}
