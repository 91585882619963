.contentRadio {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  background-color: #f1f1f1;
  position: relative;
  margin: 6px 0;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.contentRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 10px;
}

.contentRadio:hover input ~ .checkmark {
  background-color: #fff;
}

.contentRadio input:checked ~ .checkmark {
  background-color: #fff;
  border: 2px solid #ccc;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.contentRadio input:checked ~ .checkmark:after {
  display: block;
}

.contentRadio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #244af5;
}

.checkLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .checkLabel {
    font-size: 13px;
  }
}
