.inputDayPickerStyle {
  border-color: red;
}
.contentDatePicker {
  width: 100%;
}

.styleIcon {
  margin-top: 17px;
  margin-left: 19px;
  position: absolute;
  font-size: 15px;
  color: #244af5;
}
