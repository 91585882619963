.contentRadioSelect {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.contentButtonNext {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.contentButton {
  width: 300px;
  padding-bottom: 3rem;
  padding-top: 1rem;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .marginTextDescription {
    margin: 0 1.5rem;
  }

  .contentRadioSelect {
    width: 440px;
  }
}
