.contentRadioSelect {
  width: 440px;
  margin: 0 auto;
  text-align: center;
}

.contentBtnDelete {
  width: 172px;
  margin: 1rem auto;
}
.containerPLine{
  margin: 1rem 0 1.5rem 0;
  box-sizing: border-box;
}