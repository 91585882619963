.textArea {
  font-family: Montserrat;
  font-size: 13px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 16px 28px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
  margin: 0.5rem 0;
  
}


.contentTextArea{
  margin: 0 auto;
  width: 98%;
}
.textArea:focus {
  outline: none;
}

.invalid {
  border: 1px solid #D73232;
  background-color: #FFFAFA;
  color: #C00202;
}
