.containerCollap {
  margin-bottom: 1rem;
}

.collapsible {
  font-family: Montserrat;
  font-weight: 600;
  background-color: #f1f1f1;
  color: #000000;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  border: none;
  text-align: left;
  line-height: 150%;
  font-size: 12px;
}

.selected {
  background-color: #304AEB !important;
  color: white !important;
  font-weight: bold;
}

.active,
.collapsible:hover {
  background-color: #e9e5e5;
}

.collapsible:after {
  content: "\2212";
  color: #000000;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\002B";
}

.content {
  padding: 0 18px;
  max-height: null;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
}

.labelEntity {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.itemEntity {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.contentButton {
  width: 300px;
  margin: 1rem auto 2rem auto;
}

.modalBtn {
  width: 250px;
  padding: 0.5rem 0.5rem;
}

.modalCtrl {
  display: flex;
  width: 100%;
  justify-content: center;
}
