.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: 1s all;
    opacity: 0;
}
.loading.show {
    opacity: 1;
}
.loading .spin {
    border: 3px solid hsla(185, 100%, 62%, 0.2);
    border-top-color: #304AEB;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}   