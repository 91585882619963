.search {
  width: 30rem;
  margin: 0;
  max-width: 80%;
  box-sizing: border-box;
}

.inputModalSearch {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 14rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

input:focus,
input,
.inputModalSearch :focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

@media (min-width: 375px) {
  .inputModalSearch {
    width: 16rem;
  }

  .search {
    margin: 1rem auto;
  }
}

@media (min-width: 768px) {
  .inputModalSearch {
    width: 20rem;
  }
}
