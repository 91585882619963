.contentRadioSelect {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.contentButton{
  padding-bottom: 15px;
}

.formElement {
  width: 100%;
  margin: 0 auto;
}

.contentButtonNext {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.marginTextDescription {
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 2rem;
  margin: 0;
  text-align: justify;
}

@media (min-width: 768px) {
  .marginTextDescription {
    margin: 0 1.5rem;
  }

  .contentRadioSelect {
    width: 440px;
  }
}
