.mainLogo {
  width: 175px;
  height: auto;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.containerForm {
  text-align: center;
  padding: 1rem;
}

.contentErrors {
  width: 325px;
  margin: 1rem auto;
}

.icon {
  padding: 1rem 1rem;
  max-width: 3rem;
  position: absolute;
  width: 50px;
  height: 50px;
}

.btnLogin {
  width: 300px;
  margin: 0 auto;
}
.inputDanger {
  border: 1px solid #ffdddc !important;
  color: #cd1514;
}

.inputField {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 15rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 3rem;
  text-align: left;
  box-sizing: border-box;
}

.inputField:focus + .label-helper {
  visibility: visible;
}

.inputField:focus {
  border: 1px solid #99a0c1;
  border-radius: 27.5px;
  outline: none;
}

.contentInput {
  padding: 0.3rem;
}

@media (min-width: 768px) {
  .inputField {
    width: 20rem;
  }

  .mainLogo {
    width: 265px;
    height: 139px;
  }
}
