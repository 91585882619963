.add-controls {
  padding-top: 0.8rem;
}

.add-control {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 185px 2fr;
}

.icon-add-control {
  justify-self: end;
}

.container-controls {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.container-desc-controls {
  margin: 0;
}

.radio-option-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
}

.radio-option-brand {
  background-color: #f1f1f1;
  width: 218px;
  margin: 10px auto;
  text-align: left;
  padding: 1rem;
}

.updaload-file {
  width: 100%;
  text-align: center;
  /* margin-bottom: 2.5rem; */
}

.dateUseBrand {
  width: 270px;
  margin: 5px auto;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  box-sizing: border-box;
}
.desDatePicker{
  margin-bottom: 5px;
}

.classes-description {
  padding: 0 1rem 0.5rem 1rem;
}

.name-file-added {
  font-weight: bold;
}

.title-select {
  font-size: 18px;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 0.8rem;
  margin: 0;
}

.form-informacion-marca {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: column;
}

.form-informacion-marca > div {
  width: 100%;
  padding: 0 0.3rem;
}
.radio-button-next {
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 4rem;
}

@media (min-width: 375px) {
  .radio-option-brand {
    width: 270px;
  }
  .add-control {
    grid-template-columns: 254px 2fr;
  }
}

@media (min-width: 768px) {
  .form-informacion-marca {
    flex-direction: row;
    flex-wrap: row;
  }

  .add-control {
    grid-template-columns: 340px 2fr;
  }
  .container-controls {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }

  .container-desc-controls {
    margin: 0 1.5rem;
  }
}
