.grid-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  padding: 1rem 10px;
  margin-bottom: 5rem;
  max-width: 49.6rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  
}

.user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 20px 0 0 0;
}

.user-mail {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-decoration: none;
}

.grid-item {
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 4%);
  padding: 1rem 1rem;
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  height: 6.4rem;
}

.grid-item > p {
  justify-self: left;
  padding-left: 1rem;
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.item-label > a {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}

@media (min-width: 768px) {
  .grid-container {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 2rem;
    margin-bottom: 3rem;
  }

  .grid-item {
    padding: 1rem 2rem;
    grid-template-columns: 1fr 4fr 0.5fr;
  }
}
