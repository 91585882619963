.modal-title {
  margin-top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.modal-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}

.modal-head {
  text-align: left;
}

.modal-main-content {
  text-align: center;
}

.ct-modal-content {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
}

.modal-form {
  padding-bottom: 1.5rem;
}

.search-content {
  max-width: 400px;
  margin: 0 auto;
}

.button-modal {
  margin: 0.5rem auto;
  width: 60%;
}

@media (min-width: 375px) {
  .modal-title {
    margin-top: -20px;
  }
  .ct-modal-content {
    font-family: 'Montserrat', sans-serif;
    padding: 0 1rem;
  }
}
