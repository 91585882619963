.headerSection {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}
.headerLabel {
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #304aeb;
}

.headerService {
  font-weight: bold;
  font-size: 25px;
  line-height: 150%;
}

.commentsSection {
  padding: 1rem 0;
}

.commentDate {
  font-weight: bold;
  font-size: 15px;
  line-height: 120%;
  padding: 0.5rem 0;
}

.comment {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.infoBrand {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 0;
}

.detailBrandSection {
  padding-bottom: 1rem;
}

.sectionInfo {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.itemInfo {
  text-align: left;
  padding-bottom: 1rem;
}

.itemHead {
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  color: #304aeb;
}

.itemData {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.entityData {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: left;
}

.infoBrandFull {
  text-align: left;
}

.labelEntity {
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding-top: 10px;
  padding-bottom: 5px;
}

.itemEntity {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  padding-bottom: 10px;
}

.fileItem {
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
}

.itemClass {
  margin: 1rem 1rem 1rem 0;
  padding: 1rem;
  background: #f2f2f2;
}

.sectionClass {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;
}

.classId {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  padding: 0.5rem 0;
}

.headerSectionComponent {
  text-align: center;
  padding: 1rem 0;
}

.contentButton {
  width: 300px;
  margin: 0.5rem auto;
}

/* Modal */
.mainContentModal {
  width: 100%;
}

.descriptionModal {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.subTitleModal {
  font-weight: bold;
  font-size: 15px;
  line-height: 120%;
  padding: 1rem 0;
}

.modalCtrl {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.modalConrolsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modalBtn {
  width: 250px;
  padding: 0.5rem 0.5rem;
}

/* FORM NEW BUSINESS NAME*/
.formFields {
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  grid-column-gap: 20px;
}

.paymentContent {
  text-align: left;
}

.paymentSuccessHeader {
  background-color: #63ab62;
  color: aliceblue;
  padding: 10px;
  font-weight: bold;
}

.paymentRjected {
  background-color: #C14D4D;
  color: aliceblue;
  padding: 10px;
  font-weight: bold;
}
.contentPaymentSuccess {
  background-color: #e5f2d5;
  color: #37580e;
  padding: 10px;
  font-weight: bold;
}

.contentPaymentRejected {
  background-color: #E9D6D6;
  color: #780D0D;
  padding: 10px;
  font-weight: bold;
}
.dataPayment {
  font-weight: normal;
}

@media (min-width: 768px) {
  .sectionClass {
    grid-template-columns: repeat(2, 1fr);
  }
  .formFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .infoBrand {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1rem;
  }
}
