.footerWide {
  position: relative;
  padding: 1rem 3rem;
  margin-top: -130px;
  clear: both;
  text-align: left;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.1em;
  background-color: #304aeb;
  color: #ffffff;
  box-sizing: border-box;
}

.mainContentFooter {
  padding: 0.58rem 0;
}

.logoFooter {
  padding-bottom: 0.6rem;
}

@media (min-width: 768px) {
  .footerWide {
    padding: 1rem 10rem;
  }
}
