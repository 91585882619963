.containerError {
  max-width: 320px;
  margin: 0 auto;
}

.containerForm {
  text-align: center;
  padding: 1rem;
  max-width: 320px;
  margin: 0 auto;
}

.description {
  font-size: 15px;
  padding: 0 2rem 2rem 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.containerForm > form > div{
  padding-bottom: 10px;
}
