.main-container {
  min-height: 100vh;
}
.main {
  overflow: auto;
  padding-bottom: 65px;
}

@media (min-width: 768px) {
  .main {
    padding-bottom: 120px;
  }
}
