.footer {
  position: relative;
  margin-top: -50px; /* negative value of footer height */
  clear: both;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  letter-spacing: 0.1em;
  color: #2d2d2d;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .footer {
    padding: none;
  }
}
