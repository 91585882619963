.confirmation {
  padding: 0;
  width: 100%;
}

.confirmationItem {
  padding: 0.3rem 0;
}

.confirmationItem h3 {
  color: #304aeb;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
}

.confirmationItem p {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.textDescription{
  line-height: 150%;
  margin-bottom: 4rem;
}

.termsAndConditions {
  display: flex;
  align-items: center;
  height: 50px;
}

.linkText {
  color: #304aeb;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
}

.confirmation li {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  list-style: none;
}

.total {
  font-weight: 600;
  line-height: 150%;
  font-size: 23px;
}

.currency {
  font-weight: 500;
  font-size: 16px;
  color: #304aeb;
}

.terms {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}
.linkterms {
  font-weight: bold;
}

.btnConfirm {
  text-align: center;
  width: 300px;
  margin: 0 auto;
}

.labelEntity{
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 0;
}

.itemEntity{
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

/* Customize the label (the container) */
.checkitem {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 220%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkitem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.checkitem:hover input ~ .checkmark {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
.checkitem input:checked ~ .checkmark {
  background-color: #244af5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkitem input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkitem .checkmark:after {
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  border: solid white;
  border-width: 2px;
}

@media (min-width: 768px) {
  .confirmation {
    padding: 1rem;
  }
}
