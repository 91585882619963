.profile-menu {
  position: fixed;
  width: 200px;
  height: 210px;
  right: 1rem;
  top: 3.7rem;
  z-index: 50;
  background-color: white;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 10px 10px;
  padding: 0rem 1rem;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.close-profile {
  display: none;
}

.open-profile {
  display: block;
}

.container-menu-profile {
  display: grid;
  grid-auto-rows: 50px;
  grid-gap: 5px;
  align-items: center;
}

.menu-item-profile {
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  align-self: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  border-bottom: 1px solid #f5f5f5;
}

.menu-item-profile > a {
  text-decoration: none;
  color: #000000;
  padding-bottom: 10px;
}

.menu-item-profile:last-child {
  border-bottom: none;
}

.menu-item-profile > img {
  justify-self: end;
}

.user-name {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #171717;
}

.content-buttons-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-buttons-modal > div {
  min-width: 150px;
  margin: 6px;
}
