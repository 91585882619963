button {
  box-shadow: none !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.container-input-form {
  width: 100%;
}

.container-text-description {
  margin-bottom: 2rem;
}

.container-input-text-description {
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.form-fields {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  grid-column-gap: 20px;
}

.sub-description {
  padding: 0 1rem 0.5rem 2rem;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
}

.parting-line {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    93.52deg,
    #304aeb 12.6%,
    #00b9c5 65.73%,
    #73edb0 111.65%
  );
  /* margin-bottom: 1rem; */
}

.main-content-form {
  max-width: 440px;
  margin: 0 auto;
}

.container-simple {
  margin-top: 5rem;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
}

.container-select {
  width: 440px;
  margin: 0 auto;
  text-align: center;
}

.container-modulo {
  text-align: left;
  max-width: 900px;
  margin: 5rem auto;
  padding: 0 1rem;
}

.btn-success {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  width: 15rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 10px;
  border-radius: 10px;
  background: linear-gradient(
    93.52deg,
    #304aeb 12.6%,
    #00b9c5 65.73%,
    #73edb0 111.65%
  );
  box-shadow: 0px 1px 1px rgba(48, 74, 235, 0.1);
  box-sizing: border-box;
}

.btn-danger {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  width: 14rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 10px;
  border-radius: 10px;
  background: linear-gradient(92.11deg, #eb6767 1.89%, #6815d2 100%);
  box-shadow: 0px 1px 1px rgba(48, 74, 235, 0.1);
  box-sizing: border-box;
}

.btn-round {
  background-color: #304aeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 10px 1.5rem;
  border: none;
  cursor: pointer;
}

.btn-round:focus {
  outline: none;
}

.subtitle {
  padding-top: 1rem;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: #000000;
}

.subtitle-medium {
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  text-transform: uppercase;
  padding: 1.5rem;
  color: #000000;
}

.subtitle-small {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-transform: uppercase;
  padding-bottom: 0.7rem;
  color: #5e5e5e;
}

.subtitle-section {
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
}

.assets-box {
  padding-top: 1rem;
  width: 80%;
  margin: 0 auto;
}

.btn-marging {
  margin: 2rem 0 1.2rem 0;
}

.btn-back-margin {
  margin: 2rem auto;
}

.label-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  left: calc(-50vw + 50%);
  background-color: #f1f1f1;
  padding: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 12px;
}

.label-information > div {
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
}

.square-round-blue {
  cursor: pointer;
  background-color: #304aeb;
  margin: 10px auto;
  padding: 20px 16px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 30px;
  max-width: 440px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 150%;
  text-align: left;
}

.square-round-green {
  cursor: pointer;
  background-color: #44d8b9;
  margin: 10px auto;
  padding: 14px 36px;
  font-weight: normal;
  border-radius: 30px;
  max-width: 440px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 150%;
  text-align: left;
}

.input-form-field {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 16rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

.input-form-field:focus {
  border: 1px solid #99a0c1;
  border-radius: 27.5px;
  outline: none;
}

.input-select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS43Nzk4NSAwLjIxOTk1QzkuOTIwMyAwLjM2MDU3NiA5Ljk5OTE5IDAuNTUxMiA5Ljk5OTE5IDAuNzQ5OTVDOS45OTkxOSAwLjk0ODcwMSA5LjkyMDMgMS4xMzkzMyA5Ljc3OTg1IDEuMjc5OTVMNS41Mjk4NSA1LjUyOTk1QzUuMzg5MjIgNS42NzA0IDUuMTk4NiA1Ljc0OTI5IDQuOTk5ODUgNS43NDkyOUM0LjgwMTEgNS43NDkyOSA0LjYxMDQ3IDUuNjcwNCA0LjQ2OTg1IDUuNTI5OTVMMC4yMTk4NDcgMS4yNzk5NUMwLjA4NzM2NjUgMS4xMzc3OCAwLjAxNTI0MzMgMC45NDk3MjkgMC4wMTg2NzE1IDAuNzU1NDI4QzAuMDIyMDk5OCAwLjU2MTEyNyAwLjEwMDgxMiAwLjM3NTc0MSAwLjIzODIyNSAwLjIzODMyOEMwLjM3NTYzOCAwLjEwMDkxNSAwLjU2MTAyMyAwLjAyMjIwMzcgMC43NTUzMjQgMC4wMTg3NzU1QzAuOTQ5NjI1IDAuMDE1MzQ3MiAxLjEzNzY3IDAuMDg3NDcwMiAxLjI3OTg1IDAuMjE5OTVMNC45OTk4NSAzLjkzOTk1TDguNzE5ODUgMC4yMTk5NUM4Ljg2MDQ3IDAuMDc5NDk5NyA5LjA1MTEgMC4wMDA2MTAzNTIgOS4yNDk4NSAwLjAwMDYxMDM1MkM5LjQ0ODYgMC4wMDA2MTAzNTIgOS42MzkyMiAwLjA3OTQ5OTcgOS43Nzk4NSAwLjIxOTk1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==),
    linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.description-file {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3f3f3f;
}

.description-confirm {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.description {
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05rem;
  padding: 0 1rem 1rem 1rem;
}
a:link,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  color: #121f61 !important;
  text-decoration: none !important;
}

.btn-round-blue {
  background-color: #304aeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 5px 0.4rem;
  border: none;
  cursor: pointer;
}

.DayPickerInput {
  display: inline-block;
  width: 100%;
}

.DayPickerInput input {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none;
  font-size: 14px;
  width: 15.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: center;
  box-sizing: border-box;
}

.DayPickerInput input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.description-file {
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  width: 300px;
  margin: 0 auto;
}

.descripction-text {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.descripction-action {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3f3f3f;
  padding: 0 1rem;
}

.content-form-main {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.contentainer-button {
  width: 300px;
  margin: 0 auto 2rem auto;
}

.contentainer-description-text {
  width: 100%;
  margin: 0 auto;
}

.text-blue {
  color: #304aeb;
  font-weight: bold;
}

.erros-form {
  color: #d73232;
  font-size: 12px;
  padding-left: 30px;
}

.confirmation-item {
  padding: 0.3rem 1.5rem;
}

.confirmation-item h3 {
  color: #304aeb;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
}

.confirmation-item p {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.ammount {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.total {
  font-weight: 600;
  line-height: 150%;
  font-size: 23px;
}

.currency {
  font-weight: 500;
  font-size: 16px;
  color: #304aeb;
}

.description-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  padding-bottom: 1rem;
}
@media (min-width: 375px) {
  .DayPickerInput input {
    font-size: 13px;
    width: 100%;
  }
  .btn-danger {
    width: 20rem;
  }
  .square-round-blue {
    font-size: 13px;
  }
  .input-form-field {
    width: 20rem;
  }
}

@media (min-width: 768px) {
  .btn-round-blue {
    padding: 10px 1.5rem;
  }
  .square-round-blue {
    padding: 20px 40px;
    font-size: 13px;
  }
  .input-form-field {
    width: 21rem;
  }
  .label-information {
    width: 100%;
    left: 0;
    padding: 1rem;
    font-size: 14px;
  }

  .container-modulo {
    padding: 0 1rem;
  }

  .description {
    font-size: 15px;
    padding: 0 2rem 2rem 2rem;
  }

  .auth {
    width: 80%;
    margin: 1.2rem auto;
    padding: 7rem;
  }

  .btn-success {
    width: 20rem;
  }

  .subtitle {
    padding-top: 2rem;
    font-size: 22px;
  }

  .subtitle-small {
    padding-bottom: 1.5rem;
  }

  .assets-box {
    padding-top: 3rem;
  }

  .container-modulo {
    text-align: left;
    max-width: 49rem;
    margin: 5rem auto;
    padding: none;
  }

  .content-form-main {
    width: 440px;
  }
}
