.msgError {
  font-weight: 500;
  font-size: 14px;
  color: #cd1414;
  border: solid 1px #cd1414;
  width: 100%;
  margin: 1rem auto;
  border-radius: 19px;
  padding: 0.4rem;
  background-color: #fff9f9;
  box-sizing: border-box;
}
