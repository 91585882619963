.side-bar {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 3.7rem;
  z-index: 200;
  background-color: white;
  box-shadow: 0px 34px 34px rgba(0, 0, 0, 0.05);
  padding: 1rem 1rem;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.close {
  transform: translateX(100%);
}

.open {
  transform: translateX(0);
}

.container-menu {
  display: grid;
  grid-auto-rows: 50px;
  grid-gap: 5px;
  align-items: center;
}

.menu-item {
  display: grid;
  grid-template-columns: 1fr 30px;

  align-items: center;
  align-self: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #262626;
  border-bottom: 1px solid #f5f5f5;
}

.menu-item > a {
  text-decoration: none;
  padding-bottom: 10px;
}

.menu-item > a:visited {
  color: #262626;
}
.menu-item > a:hover {
  color: #304aeb;
}

.menu-item > img {
  justify-self: end;
}

.notification {
  background-color: #44d8b9;
  padding: 1px 10px;
  /* font-size: 12px; */
  border-radius: 11.5px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.03em;
}

.btn-round-close {
  background-color: #304aeb;
  padding: 3px;
  margin: 0 auto;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.iconx {
  padding-top: 17px;
  width: 12px;
}

.footer-menu-side {
  padding: 1rem 0;
}

.icons-footer-side {
  padding: 0;
}
.icons-footer-side > a {
  padding-right: 2rem;
}

.text-footer-side {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #353535;
  padding-bottom: 0;
}

.text-footer-side > a {
  text-decoration: none;
  color: #353535;
}

.text-footer-side > a > p {
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .side-bar {
    position: fixed;
    width: 300px;
    max-width: 70%;
    height: 100%;
    right: 0;
    top: 3.7rem;
    z-index: 200;
    background-color: white;
    box-shadow: 0px 34px 34px rgba(0, 0, 0, 0.05);
    padding: 1rem 1rem;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
  }

  .menu-item {
    font-size: 18px;
  }

  .notification {
    padding: 3px 15px;
  }

  .icons-footer-side {
    padding: 1rem 0;
  }
  .text-footer-side {
    padding-bottom: 2;
  }
}
