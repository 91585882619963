.textSubtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.confirmationNumber {
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #44d8b9;
}

.description {
  padding: 2rem 1rem;
  line-height: 150%;
}

.contentCenter {
  text-align: center;
}

.sincerely {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.team {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.textBlue {
  color: #244af5;
  font-weight: bold;
}

.btnSuccess {
  width: 300px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .description {
    padding: 2rem 4rem;
  }
}
