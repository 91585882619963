.btnEdit {
  background-color: #304aeb;
  color: #ffffff;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  border-radius: 24.5px;
  padding: 8px 18px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btnEdit:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
}

.btnLarge {
  width: 133px;
  text-align: left;
  height: 35px;
}

.btnIcon {
  margin-right: 10px;
  height: 15px;
  width: 15px !important;
}
