.statusContainer {
  padding: 0.5rem 1rem;
}

.title {
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.05em;
}

.status {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  padding-top: 0.2rem;
}

.icon {
  padding-right: 0.5rem;
}

.new {
  background: linear-gradient(90deg, #872a96 0%, #e73289 100%);
  color: #ffffff;
}

.processed {
  background: linear-gradient(90deg, #5ff965 0%, #fcf200 100%);
}

.impediment {
  background: linear-gradient(90deg, #ff003d 0%, #a80000 100%);
  color: #ffffff;
}

.requiredFiles {
  background: linear-gradient(90deg, #ffe600 0%, #ff5c00 100%);
}

.inProcess {
  background: linear-gradient(90deg, #6e00a2 0%, #00b3da 100%);
  color: #ffffff;
}
