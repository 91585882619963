.btnHelp {
  position: fixed;
  z-index: 10;
  right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: none;
  background: #44d8b9;
  padding: 10px 10px 0 11px;
  top: 30%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}

.imgBtnHelp {
  padding-top: 0.3rem;
  width: 33px;
}

.btnContent {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none;
}

.labelIconHelp {
  color: #166454;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  padding: 3px 0 9px;
}
