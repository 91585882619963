.containerMenuDash {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding-bottom: 4rem;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .containerMenuDash {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

/* .containerMenuDash {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 5rem;
}

@media (min-width: 375px) {
  .containerMenuDash {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .containerMenuDash {
    width: 800px;
    grid-template-columns: 1fr 1fr;
  }
} */
