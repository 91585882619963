.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.644);
}

.modalContent {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  margin-bottom: 9rem;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.close {
  color: rgb(78, 78, 78);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modalHeader {
  font-size: 11px;
  padding: 2px 16px;
}

.modalBody {
  padding: 2px 16px;
}

@media (min-width: 768px) {
  .modalHeader {
    font-size: 16px;
    padding: 2px 16px;
  }
  .modalContent {
    width: 634px;
  }

  .modal {
    padding-top: 150px;
    height: 100%;
  }
}
