.button {
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  font-weight: 700;
  font-size: 14px;

  box-sizing: border-box;
}

.btnConfirm {
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(
    93.52deg,
    #304aeb 12.6%,
    #00b9c5 65.73%,
    #73edb0 111.65%
  );
}

.btnCancel {
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(92.11deg, #eb6767 1.89%, #6815d2 100%);
}

.disabled {
  background: #e4e1e1;
  font-weight: bold;
  color: #8f8f8f;
  font-size: 14px;
}

/* @media (min-width: 375px) {
  .button {
    width: 15rem;
  }
}
@media (min-width: 768px) {
  .button {
    width: 15rem;
  }
} */
