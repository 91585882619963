.descriptionFile {
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  width: 300px;
  margin: 0 auto;
}

.contentRadioSelect {
  width: 440px;
  margin: 0 auto;
  text-align: center;
}
.descriptionSub {
  padding: 0 1rem 0.5rem 2rem;
  line-height: 150%;
}
.contentButton {
  padding: 2rem 0;
  text-align: center;
}

.btnNex {
  width: 300px;
  margin: 0 auto;
}

.titleModule {
  font-size: 18px;
  font-weight: bold;
}

.containerModule {
  width: 100%;
}

.containerPLine{
  margin-top: 1.6rem ;
}
