.header-component {
  height: 3.7rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  z-index: 90;
}

.logo {
  height: 100%;
}

.img-log {
  width: 76px;
  height: 62px;
}

.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 1rem;
}
