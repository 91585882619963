.backDrop {
  display: none;
  position: fixed;
  z-index: 100;
  padding-top: 2rem;
  left: 0;
  top: 3.1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(252, 252, 252, 0.884);
}

.closeModal {
  display: none;
}

.openModal {
  display: block;
}

.customModal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  width: 88%;
  height: 80%;
}

.close {
  float: right;
  cursor: pointer;
}


