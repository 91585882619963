.formFields {
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  grid-column-gap: 20px;
}

.oneColumnForm {
  max-width: 440px;
  margin: 0 auto;
}

.formatTextDescription {
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  padding-bottom: 1rem;
  padding-left: 2rem;
}

@media (min-width: 768px) {
  .formFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
