.contentRadioSelect {
  max-width: 440px;
  margin: 0 auto;
  text-align: center;
}

.contentForm {
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.contentButton {
  width: 300px;
  padding-bottom: 3rem;
  padding-top: 1rem;
}
