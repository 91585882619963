.module {
  padding: 1rem 0;
  /* width: 100%; */
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
}

.item {
  background-color: #f2f2f2;
  padding: 1rem;
  margin: 10px;
  box-sizing: border-box;
  text-align: left;
}

.nameItem {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
}
.descItem {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}

.closeIcon {
  color: #a4a4a4;
  font-size: 20px;
  float: right;
  margin: -10px 0;
  cursor: pointer;
}

.estimacion{
  color:blue;
  padding-left: 0;
}

@media (min-width: 375px) {
  .title {
    font-size: 18px;
  }
  .module {
    padding: 0 1rem;
  }
 
}

@media (min-width: 768px) {
  .estimacion{
    padding-left: 1rem;
  }
}
