.custom-modal {
  position: fixed;
  z-index: 200;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  top: 15%;
  left: 3%;
  width: 95%;
  transition: all 0.3s ease-out;
}
.ct-modal-header {
  width: 100%;
  text-align: right;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.modal-close-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.input-modal-field {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  font-size: 13px;
  width: 14rem;
  border: 1px solid #e1e1e1;
  border-radius: 27.5px;
  padding: 1rem 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

/*** Animations **/

.modal-open {
  animation: openModal 0.4s ease-out forwards;
}

.modal-closed {
  animation: closeModal 0.4s ease-out forwards;
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(60%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 375px) {
  .input-modal-field {
    width: 16rem;
  }
  .modal-header {
    padding: 0.5rem 0.5rem;
  }
  .ct-modal-content {
    padding: 0 0.5rem;
  }
}
@media (min-width: 768px) {
  .input-modal-field {
    width: 20rem;
  }
  .custom-modal {
    top: 15%;
    left: 25%;
    width: 50%;
  }
}
