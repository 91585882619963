.btnIconRoundBlue {
  cursor: pointer;
  background-color: #304aeb;
  margin: 10px auto;
  color: #ffffff;
  font-weight: bold;
  border-radius: 30px;
  max-width: 440px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 150%;
  text-align: left;
  padding: 20px 16px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 185px 2fr;
}

.styleIcon {
  font-size: 19px;
  align-self: center;
  justify-self: end;
  margin-left: 10px;
}

.fileName {
  font-weight: bold;
  max-width: 324px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btnGreen {
  background-color: #44d8b9;
  padding: 10px 19px;
  font-size: 10px;
  font-weight: normal;
  color: #121f61;
}

.btnDownload {
  background-color: #44d8b9;
  color: #121f61;
  grid-template-columns: 250px 2fr !important;
}

@media (min-width: 375px) {
  .btnIconRoundBlue {
    font-size: 13px;
    grid-template-columns: 254px 2fr;
  }
  .btnGreen {
    padding: 10px 36px !important;
  }
}

@media (min-width: 768px) {
  .btnIconRoundBlue {
    padding: 20px 40px;
    grid-template-columns: 340px 2fr;
  }
}
