.contentCheckBox {
  display: flex;
  align-items: center;
  height: 50px;
}

.checkitem {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 220%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkitem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.checkitem:hover input ~ .checkmark {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
.checkitem input:checked ~ .checkmark {
  background-color: #244af5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkitem input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkitem .checkmark:after {
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  border: solid white;
  border-width: 2px;
}
